@import '../../../styles/variables.scss';
.sectionWrapper {
  border-radius: $size-18;
  padding: $size-24;
  position: relative;

  margin-inline: $size-24;
  margin-block-end: $size-65;

  div h2 {
    margin-block-start: 0;
    margin-block-end: $size-12;
    transform: scale(0.8);
  }

  @media screen and (max-width: 600px) {
    div h2 {
      margin-block-start: 0;
      margin-block-end: $size-32;
    }
  }
}

.bgBlue {
  background-color: #edfafe;
}

.bgPink {
  background-color: #fff8fb;
}

.bgGreen {
  background-color: rgba(230, 248, 243, 0.6);
}

.bgPurple {
  background-color: #f8f7fe;
}

.bgDefault {
  background-color: transparent;
  box-shadow: 0 15px 45px rgb(16 30 54 / 16%);
}

.extended {
  padding-bottom: $size-65 + $size-24;
}
