.container {
  p {
    width: 100%;
    font-size: 10px;
    font-weight: 400;
    line-height: 19px;
    color: #555;
    margin-bottom: 23px;

    @media (min-width: 425px) {
      font-size: 12px;
    }

    @media (min-width: 1024px) {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}
