@import "../../../../variables.scss";

.jobListFooterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 46px;
    font-weight: 745;
    line-height: 115%;
    letter-spacing: 0;
    color: #fff;
  }

  p {
    margin-top: 18px;
    margin-bottom: 32px;
    max-width: 768px;
    font-size: 18px;
    letter-spacing: 0.75px;
    line-height: 145%;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.75px;
    padding: 16px 24px;
    background: transparent;
    box-shadow: inset 0 0 0 2px #fff;
    color: #fff;
    border-radius: 32px;
    &:hover {
      outline: none;
      background: #fff;
      color: #1d1c39;
    }
  }
}
