.modal {
  max-width: 500px !important;
  padding: 40px;
  p {
    margin: 20px 0 50px 0;
    font-size: 18px;
  }
}

.modalBtn {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 20px;
  }
}

.linkBtn {
  text-decoration: none;
}
