@import '../../styles/variables.scss';
.basicFlex {
  max-width: 1220px;
  // width: 100%; MUST NOT HAVE
  margin: 0 auto;
  padding-inline: $size-24;
  padding-block: $size-10;

  position: relative;
  color: inherit;
  transition: 0.2s;

  display: flex;
  gap: calc($size-12 / 2);
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    display: block;
  }

  &Even > * {
    flex: 1;
  }

  &MoreOnRight > *:first-child {
    flex: 4;
  }

  &MoreOnRight > *:last-child {
    flex: 3;
  }

  &MoreOnLeft > *:first-child {
    flex: 3;
  }

  &MoreOnLeft > *:last-child {
    flex: 4;
  }

  &Small {
    gap: calc($size-12 / 2);
  }

  &Medium {
    gap: $size-40;
  }

  &Large {
    gap: $size-65;
  }

  &ExtraLarge {
    gap: $size-80 * 1.2;
  }

  &Reverse {
    flex-direction: row-reverse;
  }
}
