@import '../../pages/ReportPage/styles/variables.scss';
$closeBtnColor: rgb(202, 207, 227);
$closeBtnColorHover: rgb(178, 182, 200);
$colorGray: rgb(102, 106, 133);
$colorBlue: rgb(50, 115, 246);

@mixin btn {
  height: 100%;
  border-radius: 5px;
  width: 43%;
  cursor: pointer;
}

.dailyScrumModal {
  max-width: 65% !important;
  width: 100%;
  height: 100%;
}
.dailyScrumContainer {
  padding: 40px;

  .dailyScrumContent {
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    position: relative;

    @media screen and (max-width: 1120px) {
      flex-direction: column;
    }

    & > *:nth-child(2) {
      width: 50%;

      @media screen and (max-width: 1120px) {
        width: 80%;
      }
    }

    & > *:first-child {
      width: 40%;
      max-width: 400px;
    }

    h4 {
      font-weight: 400;
      margin-block-end: $size-24;
      margin-inline-start: $size-16;
      & > span {
        display: block;
        margin-top: $size-14;
        font-size: $size-16;
        font-weight: 300;
      }
    }
  }
}
.dailyScrumHeader {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: $size-16;
  margin-block-end: $size-40;

  h2 {
    font-size: $size-32;
    font-weight: 500;
  }

  .closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    color: $closeBtnColor;
    border: none;
    background-color: white;
    cursor: pointer;
  }
  .closeBtn:hover {
    color: $closeBtnColorHover;
  }
}
.btnContainer {
  max-width: 200px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  .cancelBtn {
    border: 1px solid $colorGray;
    color: $colorGray;
    background-color: white;
    @include btn();
  }
  .submitBtn {
    border: none;
    color: white;
    background-color: $colorBlue;
    @include btn();
    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.dayDiffText {
  margin-inline-start: $size-14;
  &Safe {
    color: $color-green-dark;
  }

  &Caution {
    color: $color-orange;
  }
  &Danger {
    color: $color-brand-youtube;
  }
}
