@mixin flexBox {
  display: flex;
  flex-direction: column; 
  align-items: center;
}
.error{
  color:red;
  font-size: 25px;
}
.editSection {
  width: 100%;
  .editContainer {
    @include flexBox();
    width: 100%;
    margin: auto;
    form {
      width: 100%;
      margin-top: 7px;
      .cancelBtn{
        margin-left: 15px;
      }
      .saveBtn, .cancelBtn {
        margin-top: 16px;
        border-width: 0px;
        border-radius: 3px;
        display: inline-flex;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        max-width: 100%;
        position: relative;
        background: #0052cc;
        cursor: pointer;
        height: 2.5em;
        line-height: 2.3em;
        padding: 0px 12px;
        color: #ffffff;
        align-items: center;
        &:hover {
          background: #0065ff;
          text-decoration: inherit;
          transition-duration: 0s, 0.15s;
        }
      }
    }
  }
}
