.errorPageContainer{
    background-image: url(https://themexriver.com/appilo-theme/seo-agency/wp-content/uploads/sites/56/2021/11/hero-bg.jpg);
  
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center center;
    margin: 0;
    border: 0;
    outline: 0;
    height: 100vh;
    overflow: hidden;
  }
  
  .picture1Container{
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 500px;
  }
  
  .picture2Container{
    position: absolute;
    left: 25%;
    top: 25%;
    animation: 15s apldg_rotate_animation linear infinite;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
  }
  
  .picture3Container{
    position: absolute;
    right: 40%;
    bottom: 30%;
    animation: 20s apldg_move_animation linear infinite;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
  }
  
  .textContainer{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: 500px;
    text-align: center;
    background-color: rgb(255 255 255 / 0.3);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  
  .header{
    color:#894bb5;
    font-size: 150px;
    margin: 40px 0 ;
  }
  
  .text{
    color:#2a1737;
    font-size: 20px;
  }
  
  @-webkit-keyframes apldg_rotate_animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  @keyframes apldg_rotate_animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes apldg_move_animation {
    0% {
        -webkit-transform: translate(0) rotate(0deg);
        transform: translate(0) rotate(0deg);
    }
    25% {
        -webkit-transform: translate(200px, 100px) rotate(90deg);
        transform: translate(200px, 100px) rotate(90deg);
    }
    50% {
        -webkit-transform: transalate(600px, 200px) rotate(180deg);
        transform: transalate(600px, 200px) rotate(180deg);
    }
    75% {
        -webkit-transform: translate(-50px) rotate(270deg);
        transform: translate(-50px) rotate(270deg);
    }
    100% {
        -webkit-transform: translate(0) rotate(360deg);
        transform: translate(0) rotate(360deg);
    }
  }
  
  @keyframes apldg_move_animation {
    0% {
        -webkit-transform: translate(0) rotate(0deg);
        transform: translate(0) rotate(0deg);
    }
    25% {
        -webkit-transform: translate(200px, 100px) rotate(90deg);
        transform: translate(200px, 100px) rotate(90deg);
    }
    50% {
        -webkit-transform: transalate(600px, 200px) rotate(180deg);
        transform: transalate(600px, 200px) rotate(180deg);
    }
    75% {
        -webkit-transform: translate(-50px) rotate(270deg);
        transform: translate(-50px) rotate(270deg);
    }
    100% {
        -webkit-transform: translate(0) rotate(360deg);
        transform: translate(0) rotate(360deg);
    }
  }
  