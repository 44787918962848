@import "../../../variables.scss";

.container {
    max-width: 1280px;

    @media (max-width: 1280px) {
        max-width: 1000px;
    }
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.projectManagement {
    padding: 100px 0;
    max-width: 1000px;
    width: 100%;
}

  .jobDoneRows {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      box-sizing: border-box;
      position: relative;

      .businessContent {
          h3 {
              margin-bottom: 28px;
              margin-top: 8px;
              font-size: 36px;
              font-weight: 300;
              line-height: 133%;
              color: #1d1c35;
              width: 95%;
          }

          .jobDoneParagraph {
              margin-bottom: 37px;
              width: 90%;

              p {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 150%;
                  letter-spacing: 0.75px;
                  color: #494e6a;
                  margin-top: 0;
                  margin-bottom: 1rem;

                  a {
                      color: #4461d7;
                      text-decoration: none;
                  }
              }

              li {
                  list-style: none;
              }
          }

          .jobDoneQuote {
              position: relative;
              display: flex;
              color: #727491;
              font-weight: 500;
              font-size: 14px;
              line-height: 157%;
              letter-spacing: 0.25px;
              margin-bottom: 23px;
              align-items: flex-start;

              .quoteAvatar {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  background: linear-gradient(135deg, #7433ff 3%, #ffbcfe 94%);
                  padding: 2px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  picture {
                      width: 50px;
                      height: 50px;
                      max-width: none;

                      img {
                          width: 46px;
                          height: 46px;
                          border-radius: 50%;
                          border: 2px solid #fff;
                      }
                  }
              }

              .quoteWrap {
                  flex: 1;
                  padding-left: 16px;

                  .quoteText {
                      margin-bottom: 25px;
                      color: #727491;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 157%;
                      letter-spacing: 0.25px;
                      margin-top: 0;
                  }

                  .quoteAuthor {
                      display: flex;
                      font-size: 14px;

                      .authorName {
                          color: #ff22b1;
                      }

                      .authorRole {
                          font-size: 14px;
                      }

                      .authorRole::before {
                          content: ', ';
                      }
                  }
              }
          }

          .jobDoneCta {
              display: flex;
              align-items: center;
              line-height: 1.6;

              .ctaButton {
                  border-radius: 32px;
                  background: $primaryColor;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 150%;
                  letter-spacing: 0.75px;
                  padding: 16px 24px;
                  color: #fff;
                  text-decoration: none;
                  display: inline-flex;
                  align-items: center;
                  border: none;
                  cursor: pointer;
                  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                  &:hover {
                      outline: none;
                      box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
                  }
              }

              .ctaText {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 157%;
                  letter-spacing: 0.25px;
                  color: #727491;
                  margin-left: 1.5rem;
                  margin-top: 0;
                  margin-bottom: 0;
              }
          }
      }

      .businessPicture {
          max-width: 50%;
          overflow: hidden;

          img {
              height: auto;
              width: 100%;
              aspect-ratio: auto 600 / 480;
              max-width: 100%;
          }
      }
  }

.businessContent {
    max-width: 400px;
    width: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
        width: 100%;
        gap: 30px;
    }

    h3 {
        font-size: 22px !important;
        color: $textPrimaryColor !important;
        font-weight: 600 !important;
        margin: 0;
    }

    .jobDoneParagraph {
        color: #9191A1;
        padding: 0 !important;
        margin: 0 !important;

        ul {
            padding: 0;

        }

        li {
            margin: 12px 0;
            display: flex;

            svg {
                font-size: 20px;
                margin-right: 10px;
                fill: $textPrimaryColor
            }
        }
    }
}

.eyebrow {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0;
    color: $textPrimaryColor;
    font-weight: 600
}

.eyebrowIcon {
    font-size: 18px !important;
    margin-right: 15px;
}

.button {
    color: white;
    background-color: $primaryColor;
    padding: 16px 24px;
    font-size: 12px;
    border-radius: 99px;
    min-width: 105px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: all .25s ease-in-out;
}

.button:hover{
    background-color: #5020aa;
}

.link {
    color: $primaryColor;
    font-size: 13px;
    width: 100%;
    text-decoration: none;
    transition: all .25s ease-in-out;
}

.link:hover{
    text-decoration: underline;
}

.links {
    max-width: 417px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.bgImg {
    position: absolute;
    bottom: -500px;
    right: 0;
    z-index: -1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.subTitle{
    margin-bottom: 28px!important;
    margin-top: 8px!important;
    line-height: 133%;
    width: 95%;
    font-size: 22px;
    color: #4F5366;
    font-weight: 600;
}