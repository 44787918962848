@import '../../../../variables.scss';
$max_size: 768px;
$small_size: 1000px;


.main {
  position: relative;
  width: 100%;
}

.intro {
  width: 100%; 
  background-color: $primaryColor!important;
  margin: 0 auto;
}

.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 25px;
  padding: 0 12px;
  padding: 8rem 8rem 6rem;
  @media (max-width: $max_size) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.underline {
  background-color: #ffeab6;
  width: 100px;
  height: 4px;
  border: 0;
  margin: 3rem 0;
}

.container h1 {
  margin: 0;
  color: white;
  font-size: 3rem;
  font-weight: 300;
  @media (max-width: $max_size) {
    font-size: 2rem;
  }
}

.container p {
  font-size: 1.4rem;
  font-weight: 300;
  color: #f8f9fa;
  line-height: 1.6rem;
  @media (max-width: $max_size) {
    font-size: 1rem;
  }
}
