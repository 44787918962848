body{
  /* background-color: #F5F5F5; */
  background-color: white;
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flex{
  display: flex;
}

.alignCenter{
  align-items: center;
}

.fullWidth{
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.background-white{
  background: white;
}

.clear{
  padding: 0!important;
  margin: 0!important;
}

.defaultModalPadding{
  padding: 40px;
}

.defaultHeaderModalPadding{
  padding: 0 40px;
}
