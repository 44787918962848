.inputContainer {
  position: relative;
  display: flex;
  max-width: 300px;
  width: 100%;
  align-items: center;
  background-color: var(--ds-background-input, #fafbfc);
  border-color: var(--ds-border, #dfe1e6);
  color: var(--ds-text, #091e42);
  cursor: text;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s,
    width 0.2s ease-in-out 0s;
  &:hover {
    background-color: #efefef;
  }
}

.inputContainer input[type='text'] {
  position: relative;
  border-radius: 50px 50px 50px 50px;
  padding: 8px 26px 8px 6px;
  height: 44px;
  background-color: transparent;
  border: 0px;
  box-sizing: border-box;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0px;
  outline: none;
  width: 100%;
  line-height: 1.42857;
  &::-webkit-input-placeholder {
    opacity: 0;
    visibility: hidden;
    font-family: inherit;
    font-weight: 500;
  }

  &:focus {
    outline: 2px solid #4c9fff;
    background-color: #fff;

    &::-webkit-input-placeholder {
      opacity: 1;
      visibility: visible;
    }
  }
}

.inputContainer p {
  position: absolute;
  pointer-events: none;
  opacity: 1;
  font-family: inherit;
  font-weight: 500;
}

.inputContainer span {
  color: rgb(94, 108, 132);
  display: flex;
  position: absolute;
  pointer-events: none;
  right: 8px;
}

.inputContainer span img {
  display: inline-block;
  width: 16px;
  height: 16px;
  opacity: 0.7;
}
