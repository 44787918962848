.header{
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 56px;
    box-sizing: border-box;
    padding: 16px 20px;
    background-color: #6a2add;
    justify-content: space-between;
  
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 26% 75%, 54% 100%, 79% 64%, 100% 100%, 100% 84%, 100% 0%);
    clip-path: polygon(0% 0%, 0% 100%, 26% 75%, 54% 100%, 79% 64%, 100% 100%, 100% 84%, 100% 0%);
  }
  
  .header h1{
    display: inline-block;
    font-style: normal;
    font-stretch: normal;
    word-break: break-word;
    font-family: Poppins, sans-serif;
    color: white;
    font-size: 19px;
    line-height: 1.25;
    font-weight: 600;
  }

  .close{
    color: white !important;
    font-size: 16px;
  }
  .close:hover{
    cursor: pointer;
  }

  .defaultPadding{
    padding: 40px;
  }
  