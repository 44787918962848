@import '../../../../../variables.scss';
$max_size: 768px;
$small_size: 1000px;

.faqs .title {
  font-size: 30px;
  padding-bottom: 40px;
  padding-top: 70px;
  text-align: center;
  font-weight: 700;
  @media (max-width: $max_size) {
    font-size: 20px;
  }
}

.faqsContainer {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  margin: 0 auto;
  padding: 50px;
  text-align: center;
  width: 80%;
}

.moreInfo {
  margin-top: 40px;
}

.moreInfo a {
  text-decoration: none;
  max-width: 30%;
  margin: 0 auto;
  background-color: #fff;
  color: $primaryColor;
  border: 2px solid $primaryColor;
  border-radius: 5px;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
}

.moreInfo a:hover {
  background-color: $primaryColor;
  color: #fff;
}

.faqsContent {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.faqsContent h4 {
  padding: 20px;
}

.faqsContent span {
  padding: 20px;
  margin-right: 30px;
}

.faqsAnswer {
  font-size: 15px;
  padding: 20px;
  text-align: left;
  color: $textPrimaryColor;
}