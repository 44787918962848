$max_size: 768px;
$small_size: 1000px;

.serviceListTabs {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-left: 42px;
  @media (max-width: $max_size) {
    display: none;
    flex-direction: column;
    margin-left: 0;
  }
  div {
    align-self: center;
    @media (max-width: $max_size) {
      width: 94%;
    }
    .tab {
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 44px;
      vertical-align: middle;
      @media (max-width: $max_size) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 5px;
        font-size: 15px;
      }
    }
    .tabBtn {
      background: none;
      border: none;
      font-size: 16px;
      svg {
        margin-left: 5px;
        min-width: 10px;
        min-height: 10px;
        @media (max-width: $max_size) {
          margin-right: 50px;
          min-width: 30.5px;
          min-height: 7.5px;
        }
      }
    }
    .tabLink {
      text-decoration: none;
      color: black;
    }
  }
}

.serviceListTabsActive {
  @media (max-width: $max_size) {
    display: flex;
  }
}
