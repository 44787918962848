.backdrop {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: rgba(100, 100, 100, 0.25);
  z-index: 100;
  margin: auto;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  .modal {
    max-width: 800px;
    max-height: 80vh;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    overflow-y: auto;
    box-shadow: rgb(43 44 57 / 14%) 0px 6px 20px;
    border-bottom: 6px solid #6a2add;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }
}

.modal::-webkit-scrollbar {
  background-color: transparent;
  width: 3px;
  border-radius: 10px;
}

.modal:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
}

.fullWidth {
  width: 100%;
}
