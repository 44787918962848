$borderColor: #ebecf0;
.priorityBtnContainer {
  position: relative;
  .priorityBtn {
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: white;
    border: 2px solid white;
    cursor: pointer;
    img {
      width: 70%;
      height: 70%;
    }
  }
  .priorityBtn:hover {
    background-color: rgb(239, 239, 239);
  }
  .priorityBtnOutline {
    border: 2px solid blue;
  }

  .priorityBtnDropDown {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    width: 130px;
    height: 170px;
    background-color: white;
    border: 1px solid $borderColor;
    z-index: 100;
    border-radius: 5px;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
      .priorityDropDownBtn {
        display: flex;
        width: 100%;
        height: 38px;
        align-items: center;
        border: none;
        cursor: pointer;
        background-color: white;
        border-left: 2px solid white;
        img {
          width: 30%;
          height: 20px;
        }
        p {
          width: 70%;
          margin: 0;
          text-align: left;
          font-size: 15px;
        }
      }
      .priorityDropDownBtn:hover {
        background-color: rgb(239, 239, 239);
        border-left: 2px solid blue;
      }
    }
  }
  .showDropDownOnTop {
    top: auto;
    bottom: 33px;
  }
  .showPriorityBtnDropDown {
    display: block;
  }
}
