@mixin img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.assigneeContainer {
  position: relative;

  .assignee {
    width: 1.5rem;
    padding: 0;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    img {
      @include img();
    }
  }
  .assigneeDropdown {
    position: absolute;
    width: 300px;
    height: 250px;
    background: white;
    right: 0;
    top: 30px;
    z-index: 100;
    border: 1px solid gray;
    border-radius: 5px;
    overflow: hidden;
    .inputContainer {
      width: 100%;
      padding: 8px 5px;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      img {
        @include img();
        position: absolute;
        top: 13px;
        left: 10px;
      }
      input[type='text'] {
        width: 100%;
        height: 100%;
        padding-left: 35px;
        border: 2px solid rgb(70, 132, 247);
        border-radius: 3px;
      }
    }
    .assigneeDropdownList {
      padding: 0px;
      margin: 0;
      height: 80%;
      overflow-y: scroll;
      li {
        list-style: none;
        button {
          width: 100%;
          padding: 10px 10px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background-color: white;
          border: none;
          cursor: pointer;
          img {
            @include img();
            margin-right: 10px;
          }
        }
        button:hover {
          background-color: rgb(224, 235, 254);
        }
      }
    }
  }
  .showDropDownOnTop {
    top: auto;
    bottom: 33px;
  }
}
