@import '../../../styles/variables.scss';
@import '../Button.module.scss';

.buttonPrimary {
  @include reset-button;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  margin-block-start: $size-16;
  padding-inline: $size-32;
  border-radius: 4px;
  color: $color-white;
  height: $size-50;
  font-weight: 800;
  font-size: $size-16;
  line-height: $size-16;
  min-width: 170px;

  gap: $size-14;

  align-self: flex-start;
}

.btnColorDefault {
  background-color: $color-white;
  border: 1px solid $color-white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
  color: $color-brand;
}

.btnColorGreen {
  background-color: $color-green;
  border: 1px solid $color-green;
  box-shadow: 0 10px 25px rgba(107, 201, 80, 0.5);
}

.btnColorBlue {
  background-color: $color-blue;
  border: 1px solid $color-blue;
  box-shadow: 0 10px 25px rgba(91, 197, 250, 0.5);
}

.btnColorBrand {
  background-color: $color-brand;
  border: 1px solid $color-brand;
  box-shadow: 0 10px 25px rgba(123, 104, 238, 0.5);
}

.btnColorPink {
  background-color: $color-pink;
  border: 1px solid $color-pink;
  box-shadow: 0 10px 25px rgba(253, 113, 175, 0.5);
}

.btnColorInherit {
  background-color: inherit;
  border: 1px solid inherit;
  box-shadow: unset;
}

.btnFullWidth {
  min-width: 100%;
  align-self: stretch;
}
