.outterContainer * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.outterContainer::before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
}

.active::before {
  opacity: 1;
  pointer-events: auto;
}

.container {
  margin: 75px auto 0 auto;
  text-align: center;
  width: 100%;
  max-width: 1280px;
  padding: 15px 0;

  @media (max-width: 768px) {
    padding: 0px 30px;
  }

  h2 {
    font-size: 96px;
    font-weight: 300;
    margin: 25px 0px;

    @media (max-width: 768px) {
      font-size: 48px;
    }
  }

  .listContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    padding: 16px;

    @media (max-width: 1040px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 0px;
    }

    .staffContainer {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .positionSelector {
    display: none;
    outline: none;
    @media (max-width: 768px) {
      width: 100%;
      display: block;
      padding: 12px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.095em;
      border: 2px solid;
      border-radius: 6px;
      border-color: #976ae7;
    }
  }

  .positionSelector:visited {
    border-color: #976ae7;
  }

  .positionFilter {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: center;
    list-style-type: none;

    @media (max-width: 768px) {
      display: none;
    }

    li {
      padding: 2px 7px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.11em;
      line-height: 1.3em;
      margin: 25px 50px;
      transition: 0.8s;
      text-align: center;

      @media (max-width: 850px) {
        margin: 25px 30px;
      }
    }

    li:hover {
      background: #d5d5d5;
    }

    .active {
      background: #976ae7;
      color: white;
    }

    .active:hover {
      background: #976ae7;
    }
  }
}
