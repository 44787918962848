@import "../../variables.scss";

.careerPage {
  background-color: #fff;
  section {
    margin: 0 auto 120px auto;
  }

  .heroContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 200px 15px 50px 15px;
    color: #1d1c39;
    
    .heroContent {
      flex: 1;
      min-width: 400px;
      max-width: 596px;
      .heroTitle {
        font-size: 54px;
        letter-spacing: -1px;
        font-weight: 745;
        line-height: 115%;
      }
      .heroText {
        color: #494e6a;
        opacity: 0.85;
        width: 100%;
        max-width: 459px;
        margin-bottom: 40px;
        letter-spacing: 0.75px;
        line-height: 145%;
        font-weight: 400;
        font-size: 18px;
      }
      .heroCta {
        display: flex;
        align-items: center;
        margin-top: 50px;
        .heroCtaBtn {
          color: white;
          background-color: $primaryColor;
          padding: 16px 24px;
          font-size: 12px;
          border-radius: 99px;
          border: 1px solid $primaryColor;
          text-align: center;
          text-decoration: none;
          transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          &:hover {
            box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
            outline: none;
            cursor: pointer;
          }
          a {
            text-decoration: none;
            color: #fff;
          }
        }
        .heroCtaText {
          color: #727491;
          margin-left: 32px;
          margin-bottom: 0;
          letter-spacing: 0.75px;
          line-height: 145%;
          font-weight: 400;
        }
      }
    }
  }

  .heroImage {
    max-width: 800px;
    display: flex;
    flex: 1;
    justify-content:center;
    img {
      width: 100%;
      @media (max-width: 768px) {
        margin: 20px auto;
      }
    }
  }

  .careerGlobalContainer {
    position: relative;
  }

  .careerGlobalMap {
    position: relative;
    .globalCustomer1 {
      position: absolute;
      top: 52px;
      left: 181px;
      max-width: 188px;
    }

    .globalCustomer2 {
      position: absolute;
      top: 97px;
      left: 471px;
      max-width: 140px;
    }

    .globalCustomer3 {
      position: absolute;
      top: 366px;
      left: 243px;
      max-width: 140px;
    }
  }

  .careerGlobalText {
    position: absolute;
    right: 0;
    bottom: -78px;
    max-width: 698px;
    background: #fff;
    padding: 50px 50px 0;
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 115%;
    }
    p {
      color: #494e6a;
      margin-top: 20px;
      font-size: 18px;
      letter-spacing: 0.75px;
      line-height: 145%;
      font-weight: 400;
    }
    span {
      color: #ff22b1;
    }
  }

  .careerMission {
    position: relative;
    background-color: #1d1c39;
    color: #fff;
    max-width: 100%;
    padding-top: 120px;

    .missionContainer {
      max-width: 1224px;
      margin: 0 auto;
      .missionBackground {
        position: absolute;
        top: 144px;
        bottom: 0;
        overflow: hidden;
        opacity: 0.4;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    .missionVideo {
      min-height: 672px;
    }

    .missionText {
      text-align: center;
      h1 {
        color: #fff;
        font-size: 46px;
        font-weight: 745;
        line-height: 115%;
        margin-bottom: 0;
      }

      span {
        color: #ff22b1;
      }

      p {
        max-width: 670px;
        margin: 12px auto 40px;
        font-size: 18px;
        letter-spacing: 0.75px;
        line-height: 145%;
        font-weight: 400;
      }
    }
  }

  .careerTeamInfoContainer {
    h1 {
      color: #1d1c39;
      font-size: 46px;
      font-weight: 745;
      line-height: 115%;
      margin-top: 0px;
    }

    p {
      margin-top: 26px;
      color: #494e6a;
      font-size: 18px;
      letter-spacing: 0.75px;
      line-height: 200%;
      font-weight: 400;
    }
  }
  .careerTeamValue {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .careerTeamValueText {
      max-width: 433px;
    }

    .careerTeamValueImageContainer {
      max-width: 626px;
      height: 660px;
      img {
        height: 100%;
      }
    }
  }
  .careerTeamBalance {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .careerTeamBalanceText {
      max-width: 433px;
    }

    .careerTeamBalanceImageContainer {
      max-width: 626px;
      height: 660px;
      img {
        height: 100%;
      }
    }
  }

  .footerSection {
    background-color: $primaryColor;
    color: #fff;
    max-width: 100%;
    padding: 120px 0px;
    margin-bottom: 0px;
  }
}


.cultureSection{
  background-color: #071A2B;
  position: relative;
  color:white;
  padding: 150px;

  h2{
    font-size: 50px;
  }

  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 45%;
    justify-content: space-between;
    li{
      width: 45%;
      line-height: 25px;
      margin-bottom: 30px;
      font-size: 15px;
    }
  }

  .icon{
    padding: 15px;
    background-color: white;
    display: inline-block;
    border-radius: 15px;

    svg{
    font-size: 35px;
    border-radius: 10px;
    fill:$primaryColor;
      path{
        fill:$primaryColor;
      }
    }
  }

  .imgContainer{
    max-width: 45%;
    background: white;
    background: #6b6b6b;
    padding: 100px 20px;
    border-radius: 50px;
    img{
      width: 100%;
    }  
  }
  .contentContainer{
    gap: 5%;
    justify-content: space-between;
    align-items: center;
  }

  h3{
      color:#a675fe;
  }
}

.cultureSection::before{
  background-image: url('../../assets/decration.svg');
  position: absolute;
  top: -149px;
  left: 0;
  width: 100%;
  height: 250px;
  transform: scaleY(-0.2);
  content: "";
  background-size: 100% 100%;
}

.benefitsSection{
  margin: 0 auto 0 auto!important;
}
.benefitsSection::before{
  transform: scaleX(-1) scaleY(-0.2);
}

.container{
  max-width: 1480px;
  margin: auto;
}

.certificationImgContainer{
  max-width: 30%;
  background: transparent;
  padding: 100px 20px;
  border-radius: 50px;
  img{
    width: 100%;
  }  
}