.deleteRightContent {
  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #3d5278;
  }
  h4 {
    width: 386px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    letter-spacing: -0.01em;
    color: #c9d4ea;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: #3d5278;
  }
  .deleteCaution {
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
  }
  .deleteCaution:before {
    content: '*';
    color: red;
  }
}
.passwordInput {
  width: 386px;
  height: 56px;
  background: #f6f7f9;
  border-radius: 10px;
  border: none;
  padding-left: 32px;
}
