.changePasswordMain {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (max-width: 280px) {
    margin-top: 22%;
  }

  a {
    text-decoration: none;
    color: rgb(0, 82, 204);
  }

  img {
    margin: 40px 0 10px 0;
    width: 50%;
    height: 10%;
    align-self: center;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 0px auto 24px;
    width: 400px;
    padding: 32px 40px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    box-sizing: border-box;
    color: rgb(94, 108, 132);

    .domainField {
      margin-bottom: 20px;
      display: flex;

      p {
        font-size: 14px;
        font-weight: bold;
      }
    }
    @media (max-width: 500px) {
      width: 100%;
      max-width: 264px;
      word-break: break-all;

      .domainField {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        margin-bottom: 0px;

        p {
          text-align: left;
        }
      }

      @media (max-width: 280px) {
        max-width: 244px;
      }
    }

    h1 {
      font-size: 16px;
      align-self: center;
      margin: 0px;
      &:nth-child(2) {
        margin-bottom: 16px;
      }
    }

    input {
      margin-top: 5%;
      padding: 8px 6px;
      height: 36px;
      border: 2px solid #ccc;
    }

    input.email {
      display: block;
      @media (max-width: 500px) {
        width: 91%;
      }
    }

    input.password {
      display: block;
    }

    p {
      font-size: 12px;
      padding: 0px;
      align-self: center;
      color: red;
    }

    button {
      align-items: baseline;
      border-width: 0px;
      border-radius: 3px;
      display: inline-flex;
      font-weight: 500;
      max-width: 100%;
      position: relative;
      text-align: center;
      background-color: #0052cc;
      white-space: nowrap;
      cursor: pointer;
      padding: 0px 10px;
      vertical-align: middle;
      width: 100%;
      justify-content: center;
      color: white;
      height: 40px;
      line-height: 40px;

      &:hover {
        background-color: blue;
      }
    }

    .btnList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        border-width: 0px;
        border-radius: 3px;
        box-sizing: border-box;
        font-style: normal;
        max-width: 100%;
        position: relative;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;
        vertical-align: middle;
        width: 100%;
        font-weight: bold;
        color: #42526e;
        line-height: 40px;
        background: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0px;

        img {
          height: 18px;
          width: 18px;
          margin: auto 0 auto 8px;
          align-self: flex-start;
        }
        span {
          width: 100%;
        }
      }
    }

    .formFooter {
      color: rgb(107, 119, 140);
      padding-top: 16px;
      margin-top: 32px;
      border-top: 1px solid rgb(213, 216, 222);
      font-size: 14px;
      text-align: center;
      line-height: 20px;

      a {
        color: rgb(0, 82, 204);
        @media (max-width: 280px) {
          font-size: 12px;
        }
      }
    }

    .emailTip {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      img {
        width: 80%;
        height: 80%;
        align-self: center;
      }
      h1 {
        align-self: center;
      }
    }
  }

  p {
    color: rgb(107, 119, 140);
    align-self: center;
  }

  .registerMainFooter {
    width: 80%;
    text-align: center;
    font-size: 14px;
    @media (max-width: 280px) {
      width: 100%;
      font-size: 12px;
    }
  }
}
.emailSent {
  max-width: 40% !important;
  margin-bottom: 70px !important;
}
