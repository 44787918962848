.group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 1014px;
  width: 100%;
  @media (max-width: 1130px) {
    flex-direction: column;
    height: auto;
  }
}

.card {
  height: 952px;
  border: 1px solid #dadfe2;
  padding: 30px 15px;
  margin-right: 30px;
  border-radius: 20px;
  background-color: rgb(247,248,249);
  @media (max-width: 1130px) {
    width: 80%;
    margin: 0 auto 30px;
    height: auto;
  }
}

.card:last-child {
  margin-right: 0;
  @media (max-width: 1130px) {
    margin: 0 auto 0;
  }
}

.plan {
  height: 30px;
  margin-top: 0px;
  color: rgb(79,83,102); 
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px; 
  font-family: "Poppins",sans-serif;
  h3 {
    margin-left: 40px;
  }
}

.description {
  color: rgb(146,145,160);
  height: 96px;
  width: 200px;
  line-height: 24px;

}

.discount {
  color: rgb(79,83,102);
  font-family: "Poppins",sans-serif;
  height: 84px;
  width: 218px;
  line-height: 18.4px;

  .price {
    display: flex;
    flex-direction: row;
    height: 44px;
    font-family: "Poppins",sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 40.8px;

    .monthly_price {
      text-decoration: line-through;
      font-size: 24px;
      line-height: 31.2px;
      color: rgb(145, 145, 161);
      margin-top: 4px; 
      margin-right: 12px;
    }
  }
}

.yearly_discount_information {
  color: rgb(79,83,102);
  font-family: "Poppins",sans-serif;
  font-size: 16px;
  font-weight: 400;
  width: 218px;
  height: 24px;
  margin-top: 10px;

  .switch {
    color:rgb(221, 42, 59);
    text-decoration-line: underline;
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
    padding: 0px; 
    cursor: pointer;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  margin-top: 20px;
  @media (max-width: 1130px) {
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
    margin-top: 0;
  }
}

.action {
  width: 218.51px;
  height: 57.49px;
  border-radius: 30px;
  border: 1px solid rgb(221, 42, 59);
  background-color: rgba(221, 42, 59, 0);
  text-decoration-color: rgb(221, 42, 59);
  text-decoration-style: solid;
  font-family: "Poppins",sans-serif;
  color: rgb(221, 42, 59);
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}

.action:hover {
  background-color: rgb(221, 42, 59);
  color: white;
}

.buy_action {
  height: 40px;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 500px;
  font-size: 15px;
  font-family: "Poppins",sans-serif;
  color: rgb(221, 42, 59);
  @media (max-width: 1130px) {
    margin-left: 30px;
  }
}

.buy_action:hover {
  text-decoration: underline;
}

.service {
  margin-top: 10px;
  padding: 1px;
  .include {
    font-size: 16px;
    font-weight: 500;
  }
}

.ul {
  padding: 0;
}

.term::before {
  content: url("https://www.sortly.com/wp-content/themes/sortly_2022_redesign/assets/img/icons/check-red.svg");
  margin-right: 10px;
  width: 24px;
  max-height: 10px;
  display: inline-block;
}

.term {
  list-style: none;
  width: 218px;
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.currentPlan {
  text-align: center;
  margin-top: 15px;
}