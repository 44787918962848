.container {
  margin: 24px 0px 16px;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  color: #172b4d;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
  text-decoration-skip-ink: auto;
  justify-content:space-between;
}

.containerLeft {
  min-width: 0px;
  max-width: 100%;
  margin-bottom: 8px;
  flex: 1 1 auto;
}

.containerLeft h1 {
  font-size: 1.71429em;
  font-style: inherit;
  line-height: 1.16667;
  color: #172b4d;
  letter-spacing: -0.01em;
  margin: 0;
  font-weight: 600;
}

.containerRight {
  max-width: 100%;
  margin-bottom: 8px;
  padding-left: 32px;
  text-align: left;
  display: inline-flex;
}

.addPeopleContainer {
  flex: 1 0 auto;
  display: flex;
}

.addPeopleContainer button {
  align-items: baseline;
  border-width: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  font-weight: 600;
  max-width: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;
  background: var(--ds-background-brand-bold, #0052cc);
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  padding: 0px 10px;
  vertical-align: middle;
  width: auto;
  justify-content: center;
  color: var(--ds-text-inverse, #ffffff) !important;
}

.addPeopleContainer button span {
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  margin: 0px 2px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.addPeopleContainer button:hover {
filter: brightness(125%);

}

.manageRolesContainer {
  flex: 1 0 auto;
  display: flex;
}


.manageRolesContainer::before {
    content: "";
    display: inline-block;
    width: 4px;
}

.manageRolesContainer button {

    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    white-space: nowrap;
    background: var(--ds-background-neutral,rgba(9,30,66,0.04));
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    padding: 0px 10px;
    justify-content: center;
    color: var(--ds-text,#42526E) !important;
}


.manageRolesContainer button:hover {
    background: var(--ds-background-neutral,rgba(9,30,66,0.10));
}

.manageRolesContainer span {
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    margin: 0px 2px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--ds-text,#42526E) !important;
}