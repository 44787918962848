@import '../../../variables.scss';

.variable {
  display: inline-flex;
  position: relative;
  margin-left: -3px;
  height: 32px;
  width: 32px;
  align-items: stretch;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 200ms ease 0s, opacity 200ms ease 0s;
  box-shadow: 0 0 0 2px var(--ds-surface-overlay, #ffffff);
  &:hover,
  &:focus {
    z-index: 999;
  }
}

.createButton {
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: $primaryColor;
  cursor: pointer;
  padding: 10px 10px;
  color: #ffffff;
  @media (max-width: 800px) {
    margin-top: 20px;
  }

  &:hover {
    background: $primaryColor;
    text-decoration: inherit;
    transition-duration: 0s, 0.15s;
  }
}
.searchBarContainer {
  margin: 25px 0 0 0;
  padding: 0% 3%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.searchInputContainer {
  padding-right: 10px;
}

.container fieldset {
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: -40px;
  margin-top: 8px;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  height: 2rem;

  li {
    .avatar1 {
      z-index: 5;
      @extend .variable;
    }
    .avatar2 {
      z-index: 4;
      @extend .variable;
    }
    .avatar3 {
      z-index: 3;
      @extend .variable;
    }
    .avatar4 {
      z-index: 2;
      @extend .variable;
    }

    .avatarButton {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 3;
      color: var(--ds-text-subtlest, #7a869a);
      margin-top: -5px;
      margin-left: -3px;
      font-family: inherit;
      font-weight: 600;
      border: none;
      box-shadow: 0 0 0 2px var(--ds-surface-overlay, #ffffff);
      position: relative;
      background-color: var(--ds-background-neutral, #dfe1e6);
      &:hover {
        z-index: 999;
        background-color: var(--ds-background-neutral, #b3bac5);
      }
    }
  }
}

.active {
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  outline: 2px solid var(--ds-icon-brand, #0052cc);
  z-index: 998 !important;
}

.roleLayout {
  display: inline-block;
  position: relative;
  margin-top: -3px;
  margin-left: 10%;
}

.roleLayout button {
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
  border: 2px solid var(--ds-text-inverse, #ffffff);
  background-color: var(--ds-background-neutral, #dfe1e6);
  transition: background-color 0.1s ease-out 0s;
  cursor: pointer;
  color: var(--ds-text-subtlest, #7a869a);
}

.roleLayout button span {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  &:hover {
    background-color: #b3bac5;
  }

  &:active {
    @extend .active;
  }
}

.createCardIcon {
  border-radius: 5px;
  background-color: rgba(246, 248, 251, 0.15);
  width: 20px;
  height: 20px;
  padding: 5px;
}

.addNewButtonContainer {
  margin-left: auto;
  @media (max-width: 800px) {
    margin-left: 0;
  }
}
