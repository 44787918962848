.boxContainer{
    margin: 24px 0;
    padding: 32px 40px;
    border-radius: 8px;
    box-shadow: rgb(60 64 88 / 3%) 0px 5px 20px 0px;
    background-color: rgb(255, 255, 255);
}


.headerTitle{
    display: inline-block;
    font-style: normal;
    font-stretch: normal;
    word-break: break-word;
    font-weight: 400;
    line-height: 1.43;
    color: rgb(79, 83, 102);
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}