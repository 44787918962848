$max_size: 768px;
$small_size: 1000px;

div.middle {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-self: auto;
  div {
    border-left: 0;
    padding: 0px;
    display: flex;
    flex-direction: row;
    @media (min-width: $small_size) and (max-width: $max_size) {
      flex-direction: column;
      display: block;
    }
    flex-wrap: wrap;
    align-items: flex-start;
    div {
      margin-top: 20px;
      width: 48%;
      a {
        background: transparent;
        cursor: pointer;
        color: #373753;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        padding: 0;
        border: none;
        align-items: center;
        svg {
          min-width: 24px;
          min-height: 24px;
          margin-right: 15px;
          @media (max-width: $max_size) {
            margin-right: 12px;
          }
        }
        div {
          flex-direction: row;
          margin-top: 0;
          @media screen and (max-width: 380px) {
            flex-direction: column;
          }
          h2 {
            color: #1d1c35;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
          }
          p {
            margin-top: 15px;
            color: #494e6a;
            font-size: 14px;
            font-weight: 400;
            max-width: 250px;
            @media screen and (max-width: 380px) {
              max-width: 230px;
            }
            @media screen and (max-width: 340px) {
              max-width: 210px;
            }
          }
        }
      }
    }
  }
  .space {
    flex: 1;
  }
  a.pinkBtn {
    margin-top: 20px;
    width: 95%;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    padding: 10px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    color: #ff22b1;
    background: #fdf3fa;
    text-decoration: none;
    border: none;
    @media (max-width: $max_size) {
      width: 217px;
      margin-left: 8px;
    }
  }
}
