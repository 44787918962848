@import '../../variables.scss';

.searchBtn {
  box-sizing: border-box;
  display: flex;
  border-radius: 20px;
  text-decoration: inherit;
  outline: 0px;
  border: 0px;
  font-size: 15px;
  color: $primaryColor;
  cursor: pointer;
  background-color: #e4d6fd;
  font-weight: 500;
  padding: 12px 60px;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
  .searchIcon {
    position: absolute;
    left: 15px;
    top: 8px;
    height: 24px;
    width: 24px;
  }
}

.userSettingsBtn {
  position: absolute !important;
  bottom: 0px;
  left: 10px;
}

.userSettings {
  z-index: 99;
  position: fixed;
  bottom: 30px;
  left: 200px;
  border: none;
  color: rgb(79, 83, 102);
  background: white;
  box-shadow: rgb(60 64 88 / 14%) 0px 20px 30px 0px;
  max-width: 260px;
  width: 100%;
  .item {
    padding: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: none;
    text-decoration: none;
    font-size: 12px;
    &:hover {
      background-color: rgb(243, 244, 250);
      border-radius: 10px;
      cursor: pointer;
    }
    &:visited {
      color: rgb(79, 83, 102);
    }
  }

  img {
    max-width: 30px;
    height: 30px;
    width: 100%;
    border-radius: 50%;
    margin-right: 10px;
  }

  hr {
    background-color: rgb(238, 239, 246);
    border: 0;
    height: 1px;
    width: 100%;
  }

  svg {
    margin-right: 18px;
    font-size: 20px;
  }
}
