@import "../../../../variables.scss";

footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: box-shadow 200ms ease 0s;
  z-index: 1;
  padding: 30px 0px 8px;
  box-shadow: none;

}

.footerContent {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top:20px;
}

.footerContent .cancelButton {
  border-width: 0px;
  border-radius: 3px;
  display: inline-flex;
  font-style: normal;
  font-family: inherit;
  font-weight: 600;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;
  background: none;
  cursor: pointer;
  line-height: 2.28571em;
  padding: 0px 10px;
  justify-content: center;
  color: $primaryColor;
}

.footerContent .cancelButton:hover {
  text-decoration: underline;
  filter: brightness(1.3);
}
.footerContent .cancelButton:active {
  text-decoration: none;
}

.footerContent .cancelButton span {
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  margin: 0px 2px;
}

.addButton {
  margin-left: 8px;
  border-width: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  font-style: normal;
  font-family: inherit;
  font-weight: 700;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: rgba(9, 30, 66, 0.04);
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  padding: 0px 10px;
  color: rgb(165, 173, 186) !important;
}

.addButton:hover {
  filter: brightness(1.2);
}

.activeAddButton {
  margin-left: 8px;
  border-width: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  font-style: normal;
  font-family: inherit;
  font-weight: 700;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  padding: 0px 10px;
  background: $primaryColor;
  color: white;
}

.activeAddButton:hover {
  filter: brightness(1.2);
}

.activeAddButton:active {
  filter: none;
  background: $primaryColor;
}
