@import "../../variables.scss";

.homePage {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  .jobDoneSection {
    .jobDoneContainer {
      margin-right: auto;
      margin-left: auto;
      .jobDoneHeader {
        margin: 0 auto;
        text-align: center;
        max-width: 869px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
          font-size: 50px;
          font-weight: 700;
          line-height: 112%;
          letter-spacing: 1px;
          margin-bottom: 7px;
          margin-top: 50px;
          color: rgb(29, 28, 53);
          .headerPink {
            color: #ff22b1;
            font-weight: bolder;
          }
          .headerIndigo {
            color: $primaryColor;
          }
        }
        .jobDoneText {
          margin: 0 auto;
          margin-bottom: 70px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.75px;
          color: #494e6a;
        }
      }
      .jobDoneRows {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        .businessContent {
          h3 {
            margin-bottom: 28px;
            margin-top: 8px;
            font-size: 36px;
            font-weight: 300;
            line-height: 133%;
            color: #1d1c35;
            width: 95%;
          }
          .jobDoneParagraph {
            margin-bottom: 37px;
            width: 90%;
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: 0.75px;
              color: #494e6a;
              margin-top: 0;
              margin-bottom: 1rem;
              a {
                color: #4461d7;
                text-decoration: none;
              }
            }
            li{
              list-style: none;
            }
          }
          .jobDoneQuote {
            position: relative;
            display: flex;
            color: #727491;
            font-weight: 500;
            font-size: 14px;
            line-height: 157%;
            letter-spacing: 0.25px;
            margin-bottom: 23px;
            align-items: flex-start;
            .quoteAvatar {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background: linear-gradient(135deg, #7433ff 3%, #ffbcfe 94%);
              padding: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              picture {
                width: 50px;
                height: 50px;
                max-width: none;
                img {
                  width: 46px;
                  height: 46px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                }
              }
            }
            .quoteWrap {
              flex: 1;
              padding-left: 16px;
              .quoteText {
                margin-bottom: 25px;
                color: #727491;
                font-weight: 500;
                font-size: 14px;
                line-height: 157%;
                letter-spacing: 0.25px;
                margin-top: 0;
              }
              .quoteAuthor {
                display: flex;
                font-size: 14px;
                .authorName {
                  color: #ff22b1;
                }
                .authorRole {
                  font-size: 14px;
                }
                .authorRole::before {
                  content: ', ';
                }
              }
            }
          }
          .jobDoneCta {
            display: flex;
            align-items: center;
            line-height: 1.6;
            .ctaButton {
              border-radius: 32px;
              background: $primaryColor;
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              letter-spacing: 0.75px;
              padding: 16px 24px;
              color: #fff;
              text-decoration: none;
              display: inline-flex;
              align-items: center;
              border: none;
              cursor: pointer;
              transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
              &:hover {
                outline: none;
                box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
              }
            }
            .ctaText {
              font-size: 14px;
              font-weight: 500;
              line-height: 157%;
              letter-spacing: 0.25px;
              color: #727491;
              margin-left: 1.5rem;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
        .businessPicture {
          max-width: 50%;
          overflow: hidden;
          img {
            height: auto;
            width: 100%;
            aspect-ratio: auto 600 / 480;
            max-width: 100%;
          }
        }
      }
      .collaborationRows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        picture {
          max-width: 50%;
          overflow: hidden;
          img {
            max-width: 100%;
            height: auto;
            width: 100%;
            aspect-ratio: auto 600 / 480;
          }
        }
        .collaborationContent {
          min-width: 440px;
          max-width: 500px;
          padding-left: 50px;
          h3 {
            margin-bottom: 29px;
            margin-top: 0px;
            font-size: 36px;
            font-weight: 100;
            line-height: 133%;
            color: #1d1c35;
            width: 87%;
          }
          .jobDoneParagraph {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            width: 94%;
            margin-bottom: 37px;
            p {
              letter-spacing: 0.75px;
              color: #494e6a;
              margin-top: 0;
              margin-bottom: 1rem;
              a {
                text-decoration: none;
                color: #4461d7;
              }
            }
          }
          .jobDoneQuote {
            position: relative;
            display: flex;
            color: #727491;
            font-weight: 500;
            font-size: 14px;
            line-height: 157%;
            letter-spacing: 0.25px;
            margin-bottom: 37px;
            .quoteAvatar {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background: linear-gradient(135deg, #7433ff 3%, #ffbcfe 94%);
              padding: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              picture {
                width: 50px;
                height: 50px;
                max-width: none;
                img {
                  width: 46px;
                  height: 46px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                }
              }
            }
            .quoteWrap {
              flex: 1;
              padding-left: 16px;
              .quoteText {
                margin-bottom: 25px;
                color: #727491;
                font-weight: 500;
                font-size: 14px;
                line-height: 157%;
                letter-spacing: 0.25px;
                margin-top: 0;
              }
              .quoteAuthor {
                display: flex;
                font-size: 14px;
                .authorName {
                  color: #ff22b1;
                }
                .authorRole {
                  font-size: 13px;
                }
                .authorRole::before {
                  content: ', ';
                }
              }
            }
          }
          .jobDoneCta {
            display: flex;
            align-items: center;
            line-height: 1.6;
            .ctaButton {
              border-radius: 32px;
              background: $primaryColor;
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              letter-spacing: 0.75px;
              padding: 16px 24px;
              color: #fff;
              text-decoration: none;
              border: none;
              cursor: pointer;
              transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
              &:hover {
                outline: none;
                box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
              }
            }
            .ctaText {
              font-size: 14px;
              font-weight: 500;
              line-height: 157%;
              letter-spacing: 0.25px;
              color: #727491;
              margin-left: 1.5rem;
            }
          }
        }
      }
      .projectManagementRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        background-color: $primaryColor;
        position: relative;
        .projectManagementContent {
          min-width: 440px;
          max-width: 500px;
          h3 {
            margin-bottom: 29px;
            margin-top: 0;
            font-size: 28px;
            font-weight: 600;
            line-height: 133%;
            letter-spacing: 1px;
            color: #1d1c35;
            width: 95%;
          }
          .jobDoneParagraph {
            margin-bottom: 37px;
            width: 90%;
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: 0.75px;
              color: #494e6a;
              margin-top: 0;
              margin-bottom: 1rem;
              a {
                color: #4461d7;
                text-decoration: none;
              }
            }
          }
          .jobDoneQuote {
            position: relative;
            display: flex;
            color: #727491;
            font-weight: 500;
            font-size: 14px;
            line-height: 157%;
            letter-spacing: 0.25px;
            margin-bottom: 37px;
            align-items: flex-start;
            .quoteAvatar {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background: linear-gradient(135deg, #7433ff 3%, #ffbcfe 94%);
              padding: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              picture {
                width: 50px;
                height: 50px;
                max-width: none;
                img {
                  width: 46px;
                  height: 46px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                }
              }
            }
            .quoteWrap {
              flex: 1;
              padding-left: 16px;
              .quoteText {
                margin-bottom: 25px;
                color: #727491;
                font-weight: 500;
                font-size: 14px;
                line-height: 157%;
                letter-spacing: 0.25px;
                margin-top: 0;
                width: 88%;
              }
              .quoteAuthor {
                display: flex;
                font-size: 14px;
                .authorName {
                  color: #ff22b1;
                }
                .authorRole {
                  font-size: 14px;
                }
                .authorRole::before {
                  content: ', ';
                }
              }
            }
          }
          .jobDoneCta {
            display: flex;
            align-items: center;
            .ctaButton {
              border-radius: 32px;
              background: $primaryColor;
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              letter-spacing: 0.75px;
              padding: 16px 24px;
              color: #fff;
              text-decoration: none;
              border: none;
              cursor: pointer;
              transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
              &:hover {
                outline: none;
                box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
              }
            }
            .ctaText {
              font-size: 14px;
              font-weight: 500;
              line-height: 157%;
              letter-spacing: 0.25px;
              color: #727491;
              margin-left: 1.5rem;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
        picture {
          max-width: 50%;
          overflow: hidden;
          img {
            height: auto;
            width: 100%;
            max-width: 100%;
            @media (max-width: 768px) {
              display: block;
            }
          }
        }
      }
    }
  }
  .switchingSection {
    background-color: #f5f7fa;
    .switchingContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-right: auto;
      margin-left: auto;
      .switchingContent {
        min-width: 420px;
        h3 {
          margin-bottom: 35px;
          max-width: 473px;
          font-size: 50px;
          font-weight: 700;
          line-height: 112%;
          letter-spacing: 1px;
          margin-top: 0;
        }
        p {
          margin-bottom: 44px;
          max-width: 354px;
          font-size: 20px;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.75px;
          margin-top: 0;
        }
        .switchingCta {
          display: flex;
          align-items: center;
          line-height: 1.6;
          .ctaButton {
            border-radius: 32px;
            background: $primaryColor;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.75px;
            padding: 16px 24px;
            color: #fff;
            text-decoration: none;
            &:hover {
              outline: none;
              box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
            }
          }
          .ctaText {
            font-size: 14px;
            font-weight: 500;
            line-height: 157%;
            letter-spacing: 0.25px;
            color: #727491;
            margin-left: 1.5rem;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      .switchingPicture {
        max-width: 600px;
        overflow: hidden;
        img {
          max-width: 100%;
          height: auto;
          width: 687px;
          aspect-ratio: auto 687 / 428;
        }
      }
    }
  }
  .cardsSection {
    position: relative;
    .cardsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      h3 {
        max-width: 760px;
        margin-bottom: 25px;
        font-size: 36px;
        font-weight: 700;
        line-height: 112%;
        letter-spacing: 1px;
        color: rgb(33, 37, 41);
        span {
          color: #ff22b1;
        }
      }
      .cardsParagraph {
        max-width: 627px;
        font-size: 20px;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 0.75px;
        color: #494e6a;
        text-align: center;
        p {
          color: #494e6a;
          font-size: 20px;
          font-weight: 400;
          line-height: 160%;
          margin-top: 0;
          margin-bottom: 1rem;
          span {
            color: #ff22b1;
          }
        }
      }
      .cardsCards {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 40px;
        margin-top: 60px;
        .eachCards {
          box-shadow: 0px 15px 50px 0px rgb(11 11 11 / 8%);
          margin-right: 20px;
          max-width: 350px;
          width: 100%;
          background: #efefef;
          border-radius: 10px;
          overflow: hidden;
          text-align: left;
          transition: transform 0.2s ease-in-out;
          text-decoration: none;
          color: #373753;
          cursor: pointer;
          @media (max-width: 280px) {
            margin-right: 0;
          }
          picture {
            display: block;
            max-width: 100%;
            overflow: hidden;
            max-height: 225px;
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
              border-style: none;
            }
          }
          .cardsWrap {
            padding: 25px 20px;
            .cardsTag {
              display: inline-block;
              background: #d6dee7;
              border-radius: 8px;
              padding: 3px 8px;
              color: #727491;
              font-size: 14px;
            }
            .cardsName {
              font-size: 21px;
              line-height: 145%;
              color: #00163b;
              margin-top: 8px;
              font-weight: 700;
            }
            .cardsCta {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 30px;
              .cardsHeart {
                display: flex;
                align-items: center;
                color: #00163b;
                svg {
                  margin-right: 16px;
                  fill: #ff22b1;
                  color: #ff22b1;
                  @media (max-width: 280px) {
                    margin-right: 9px;
                  }
                }
                span {
                  font-size: 16px;
                  line-height: 150%;
                  color: #727491;
                  font-weight: 600;
                  cursor: pointer;
                }
              }
              button {
                border-radius: 24px;
                background: transparent;
                box-shadow: inset 0 0 0 2px #1d1c39;
                color: #1d1c39;
                font-size: 14px;
                line-height: 157%;
                padding: 9px 16px;
                font-weight: 600;
                min-height: 27px;
                letter-spacing: 0.25px;
                display: inline-flex;
                align-items: center;
                user-select: none;
                text-align: center;
                border: none;
                cursor: pointer;
                transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                @media (max-width: 280px) {
                  padding: 9px 12px;
                }
                &:hover {
                  outline: none;
                  background: #1d1c39;
                  color: #fff;
                }
              }
            }
          }
          &:hover {
            transform: translateY(-10px);
          }
        }
        .eachCards:last-child {
          margin-right: 0;
        }
      }
      .cardsButtons {
        display: flex;
        align-items: center;
        margin-top: 53px;
        box-sizing: border-box;
        text-align: center;
        .ctaButton {
          border-radius: 5px;
          background: $primaryColor;
          margin: 0 5px;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.75px;
          padding: 16px 24px;
          color: #fff;
          display: inline-flex;
          align-items: center;
          user-select: none;
          text-align: center;
          border: none;
          cursor: pointer;
          transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          text-decoration: none;
          &:hover {
            outline: none;
            box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
          }
        }
        .viewTemplateButton {
          border-radius: 5px;
          background: transparent;
          box-shadow: inset 0 0 0 2px #1d1c39;
          color: #1d1c39;
          margin: 0 5px;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.75px;
          padding: 16px 24px;
          display: inline-flex;
          align-items: center;
          user-select: none;
          text-align: center;
          border: none;
          cursor: pointer;
          transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          text-decoration: none;
          &:hover {
            outline: none;
            background: #1d1c39;
            color: #fff;
          }
        }
      }
    }
  }
  .clientsSection {
    padding: 120px 0;
    background-color: #fff;
    .clientsContainer {
      display: flex;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      picture {
        width: 50%;
        img {
          width: 90%;
          height: auto;
        }
      }
      .clientsContent {
        width: 50%;
        h3 {
          max-width: 382px;
          margin-bottom: 30px;
          font-size: 42px;
          font-weight: 700;
          line-height: 114%;
          letter-spacing: 1px;
          color: #1d1c35;
          margin-top: 0;
        }
        p {
          max-width: 462px;
          margin-bottom: 50px;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.75px;
          color: #494e6a;
        }
        .clientsQuote {
          position: relative;
          display: flex;
          color: #727491;
          font-weight: 500;
          font-size: 14px;
          line-height: 157%;
          letter-spacing: 0.25px;
          align-items: flex-start;
          max-width: 479px;
          margin-bottom: 50px;
          .quoteAvatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(135deg, #7433ff 3%, #ffbcfe 94%);
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            picture {
              width: 50px;
              height: 50px;
              max-width: none;
              img {
                width: 46px;
                height: 46px;
                border-radius: 50%;
                border: 2px solid #fff;
              }
            }
          }
          .quoteWrap {
            flex: 1;
            padding-left: 16px;
            .quoteText {
              margin-bottom: 25px;
              color: #727491;
              font-weight: 500;
              font-size: 14px;
              line-height: 157%;
              letter-spacing: 0.25px;
              margin-top: 0;
            }
            .quoteAuthor {
              display: flex;
              font-size: 14px;
              .authorName {
                color: #ff22b1;
              }
              .authorRole {
                font-size: 14px;
              }
              .authorRole::before {
                content: ', ';
              }
            }
          }
        }
        .clientsCta {
          display: flex;
          align-items: center;
          line-height: 1.6;
          .ctaButton {
            border-radius: 5px;
            background: $primaryColor;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.75px;
            padding: 16px 24px;
            color: #fff;
            text-decoration: none;
            text-align: center;
            &:hover {
              outline: none;
              box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
            }
          }
          .ctaText {
            font-size: 14px;
            font-weight: 500;
            line-height: 157%;
            letter-spacing: 0.25px;
            color: #727491;
            margin-left: 1.5rem;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .supportSection {
    .supportContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      width:100%;
      @media (max-width: 768px) {
        width: 80%;
      } 
      .supportContent {
        font-size: 36px;
        font-weight: 700;
        line-height: 112%;
        max-width: 400px;
        width: 100%;
        h3 {
          font-size: 36px;
          font-weight: 700;
          line-height: 112%;
          letter-spacing: 1px;
          color: #1d1c35;
          margin-bottom: 2rem;
          font-family: inherit;
          margin-top: 0;
          span {
            color: #ff22b1;
          }
        }
        p {
          margin-bottom: 60px;
          max-width: 577px;
          font-size: 20px;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.75px;
          color: #1d1c35;
          margin-top: 0;
        }
        .supportButtons {
          display: flex;
          margin-bottom: 40px;
          .supportCall {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 40px;
            transition: transform 0.2s ease-in-out;
            color: #373753;
            text-decoration: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.6;
            picture {
              cursor: pointer;
              img {
                vertical-align: middle;
                border-style: none;
                width: 50px;
                aspect-ratio: auto 50 / 50;
                height: 50px;
              }
            }
            span {
              font-size: 20px;
              margin-left: 17px;
              cursor: pointer;
            }
            &:hover {
              color: #000 !important;
              transform: scale(1.05);
            }
          }
          .supportContact {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 40px;
            transition: transform 0.2s ease-in-out;
            color: #373753;
            text-decoration: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.6;
            picture {
              cursor: pointer;
              img {
                vertical-align: middle;
                border-style: none;
                width: 50px;
                aspect-ratio: auto 50 / 50;
                height: 50px;
              }
            }
            span {
              font-size: 20px;
              margin-left: 17px;
              cursor: pointer;
            }
            &:hover {
              color: #000 !important;
              transform: scale(1.05);
            }
          }
        }
        .supportParagraph {
          font-size: 16px;
          color: #727491;
        }
        .supportQuote {
          position: relative;
          display: flex;
          color: #727491;
          font-weight: 500;
          font-size: 14px;
          line-height: 157%;
          letter-spacing: 0.25px;
          margin-bottom: 23px;
          align-items: flex-start;
          .quoteAvatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(135deg, #7433ff 3%, #ffbcfe 94%);
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            picture {
              width: 50px;
              height: 50px;
              max-width: none;
              img {
                width: 46px;
                height: 46px;
                border-radius: 50%;
                border: 2px solid #fff;
              }
            }
          }
          .quoteWrap {
            flex: 1;
            padding-left: 16px;
            .quoteText {
              margin-bottom: 25px;
              color: #727491;
              font-weight: 500;
              font-size: 14px;
              line-height: 157%;
              letter-spacing: 0.25px;
              margin-top: 0;
            }
            .quoteAuthor {
              display: flex;
              font-size: 14px;
              .authorName {
                color: #ff22b1;
              }
              .authorRole {
                font-size: 14px;
              }
              .authorRole::before {
                content: ', ';
              }
            }
          }
        }
      }
      .supportPicture {
        max-width: 470px;
        width: 70%;
        img {
          width: 100%;
          height: auto;
          aspect-ratio: auto 470 / 454;
        }
      }
    }
  }
}
@media (min-width: 1440px) {
  .homePage {
    .cardsSection {
      .cardsContainer {
        max-width: 1224px;
      }
    }
    .supportSection {
      .supportContainer {
        max-width: 1224px;
      }
    }
  }
}
@media (min-width: 1280px) {
  .homePage {
    .switchingSection {
      .switchingContainer {
        max-width: 1164px;
      }
    }
    .cardsSection {
      .cardsContainer {
        max-width: 1164px;
      }
    }
    .clientsSection {
      .clientsContainer {
        max-width: 1164px;
      }
    }
    .supportSection {
      .supportContainer {
        max-width: 1164px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .homePage {
    .jobDoneSection {
      padding-bottom: 100px;
    }
    .switchingSection {
      padding: 100px 0;
    }
    .clientsSection {
      padding: 100px 0;
    }
  }
}
@media (max-width: 997px) {
  .homePage {
    .jobDoneSection {
      .jobDoneContainer {
        .jobDoneHeader {
          h3 {
            font-size: 30px;
          }
        }
        .jobDoneText {
          font-size: 16px;
        }
        .jobDoneRows {
          .businessContent {
            h3 {
              font-size: 26px;
            }
            .jobDoneParagraph {
              font-size: 15px;
              width: 100%;
            }
          }
        }
        .collaborationRows {
          .collaborationContent {
            h3 {
              font-size: 26px;
              width: 94%;
            }
            .jobDoneParagraph {
              font-size: 14px;
              width: 100%;
            }
          }
        }
        .projectManagementRow {
          .projectManagementContent {
            h3 {
              font-size: 26px;
              width: 100%;
            }
            .jobDoneParagraph {
              width: 100%;
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .switchingSection {
      .switchingContainer {
        .switchingContent {
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    .cardsSection {
      .cardsContainer {
        h3 {
          font-size: 30px;
        }
        .cardsParagraph {
          p {
            font-size: 16px;
          }
        }
      }
    }
    .clientsSection {
      .clientsContainer {
        .clientsContent {
          h3 {
            font-size: 5px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .supportSection {
      .supportContainer {
        .supportContent {
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 16px;
          }
          .supportParagraph {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 995px) {
  .homePage {
    .jobDoneSection {
      .jobDoneContainer {
        .jobDoneRows {
          .businessContent {
            .jobDoneParagraph {
              p {
                font-size: 14px;
              }
            }
            .jobDoneQuote {
              font-size: 13px;
              .quoteWrap {
                .quoteAuthor {
                  flex-direction: column;
                  font-size: 13px;
                  .authorRole {
                    font-size: 12px;
                  }
                  .authorRole::before {
                    content: '';
                  }
                }
              }
            }
          }
        }
        .collaborationRows {
          .collaborationContent {
            .jobDoneQuote {
              font-size: 13px;
              .quoteWrap {
                .quoteAuthor {
                  flex-direction: column;
                  .authorRole::before {
                    content: '';
                  }
                }
              }
            }
          }
        }
        .projectManagementRow {
          .projectManagementContent {
            .jobDoneQuote {
              font-size: 13px;
              .quoteWrap {
                .quoteText {
                  width: 100%;
                }
                .quoteAuthor {
                  flex-direction: column;
                  .authorRole::before {
                    content: '';
                  }
                }
              }
            }
          }
        }
      }
    }
    .clientsSection {
      .clientsContainer {
        .clientsContent {
          .clientsQuote {
            font-size: 13px;
            .quoteWrap {
              .quoteText {
                font-size: 13px;
              }
              .quoteAuthor {
                flex-direction: column;
                .authorName {
                  font-size: 13px;
                }
                .authorRole {
                  font-size: 12px;
                }
                .authorRole::before {
                  content: ' ';
                }
              }
            }
          }
        }
      }
    }
    .supportSection {
      .supportContainer {
        .supportContent {
          .supportQuote {
            font-size: 13px;
            .quoteWrap {
              .quoteText {
                font-size: 13px;
              }
              .quoteAuthor {
                flex-direction: column;
                .authorRole {
                  font-size: 12px;
                }
                .authorRole::before {
                  content: '';
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .homePage {
    .cardsSection {
      .cardsContainer {
        .cardsCards {
          gap: 20px;
          margin-top: 40px;
        }
      }
    }
  }
}
@media (max-width: 990px) {
  .supportSection {
    .supportContainer {
      .supportContent {
        .supportButtons {
          .supportCall {
            margin-right: 20px;
            span {
              font-size: 14px;
              margin-left: 14px;
            }
          }
          .supportContact {
            margin-right: 20px;
            span {
              font-size: 14px;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 987px) {
  .homePage {
    .cardsSection {
      .cardsContainer {
        .cardsCards {
          .eachCards {
            .cardsWrap {
              padding: 20px;
              .cardsTag {
                font-size: 13px;
              }
              .cardsName {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .homePage {
    .clientsSection {
      .clientsContainer {
        picture {
          width: 35%;
          img {
            display: block;
            max-width: 200px;
            margin: 0 auto;
            margin-bottom: 10px;
          }
        }
        .clientsContent {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .homePage {
    .jobDoneSection {
      padding-bottom: 80px;
    }
    .switchingSection {
      padding: 80px 0;
    }
    .clientsSection {
      padding: 80px 0;
    }
  }
}
@media (min-width: 731px) {
  .homePage {
    .jobDoneSection {
      .jobDoneContainer {
        .jobDoneRows {
          .businessContent {
            padding-left: 0;
          }
        }
        .projectManagementRow {
          .projectManagementContent {
            padding-left: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 730px) {
  .homePage {
    .jobDoneSection {
      .jobDoneContainer {
        .jobDoneHeader {
          .jobDoneText {
            margin-bottom: 30px;
          }
        }
        .jobDoneRows {
          flex-direction: column;
          .businessContent {
            min-width: auto;
            width: 100%;
            padding: 0;
            margin-top: 20px;
          }
          .businessPicture {
            max-width: 300px;
          }
        }
        .collaborationRows {
          flex-direction: column;
          picture {
            max-width: 300px;
          }
          .collaborationContent {
            min-width: auto;
            width: 100%;
            padding: 0;
            margin-top: 20px;
          }
        }
        .projectManagementRow {
          flex-direction: column-reverse;
          margin-bottom: 0;
          picture {
            max-width: 300px;
          }
          .projectManagementContent {
            min-width: auto;
            width: 100%;
            padding: 0;
            margin-top: 20px;
            @media (max-width: 768px) {
              width: 60%;
            }
          }
        }
      }
    }
    .switchingSection {
      .switchingContainer {
        flex-direction: column;
        text-align: center;
        .switchingContent {
          min-width: 0;
        }
        .switchingPicture {
          max-width: 300px;
          margin-top: 50px;
        }
      }
    }
    .supportSection {
      .supportContainer {
        flex-direction: column-reverse;
        .supportContent {
          min-width: auto;
          width: 100%;
          padding: 0;
          margin-top: 20px;
        }
        .supportPicture {
          max-width: 230px;
        }
      }
    }
  }
}
@media (max-width: 720px) {
  .homePage {
    .clientsSection {
      .clientsContainer {
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
        picture {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .homePage {
    .cardsSection {
      .cardsContainer {
        .cardsCards {
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
        }
      }
    }
  }
}
@media (min-width: 601px) and (max-width: 1000px) {
  .homePage {
    .cardsSection {
      .cardsContainer {
        .cardsCards {
          .eachCards {
            .cardsWrap {
              .cardsCta {
                justify-content: center;
                .cardsHeart {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 568px) {
  .homePage {
    .jobDoneSection {
      .jobDoneContainer {
        .jobDoneHeader {
          h3 {
            font-size: 23px;
          }
          .jobDoneText {
            font-size: 14px;
          }
        }
        .collaborationRows {
          .collaborationContent {
            h3 {
              font-size: 20px;
            }
          }
        }
        .projectManagementRow {
          .projectManagementContent {
            h3 {
              font-size: 19px;
            }
          }
        }
      }
    }
    .switchingSection {
      .switchingContainer {
        .switchingContent {
          h3 {
            font-size: 23px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .cardsSection {
      .cardsContainer {
        h3 {
          font-size: 23px;
        }
        .cardsParagraph {
          p {
            font-size: 14px;
          }
        }
      }
    }
    .supportSection {
      .supportContainer {
        .supportContent {
          h3 {
            font-size: 23px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .homePage {
    .cardsSection {
      .cardsContainer {
        .cardsButtons {
          flex-direction: column;
          .ctaButton {
            margin: 5px 0;
          }
          .viewTemplateButton {
            margin: 5px 0;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .homePage {
    .jobDoneSection {
      .jobDoneContainer {
        .jobDoneRows {
          .businessContent {
            .jobDoneCta {
              flex-direction: column;
              .ctaText {
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
        }
        .collaborationRows {
          .collaborationContent {
            .jobDoneCta {
              flex-direction: column;
              .ctaText {
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
        }
        .projectManagementRow {
          .projectManagementContent {
            .jobDoneCta {
              flex-direction: column;
              .ctaText {
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    .switchingSection {
      .switchingContainer {
        .switchingContent {
          .switchingCta {
            flex-direction: column;
            .ctaText {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .clientsSection {
      .clientsContainer {
        .clientsContent {
          .clientsCta {
            flex-direction: column;
            .ctaButton {
              font-size: 13px;
            }
            .ctaText {
              font-size: 13px;
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .supportSection {
      .supportContainer {
        .supportContent {
          .supportButtons {
            justify-content: space-between;
            .supportCall {
              picture {
                img {
                  width: 40px;
                  height: 40px;
                }
              }
              span {
                font-size: 13px;
                margin-left: 13px;
              }
            }
            .supportContact {
              picture {
                img {
                  width: 40px;
                  height: 40px;
                }
              }
              span {
                font-size: 13px;
                margin-left: 13px;
              }
            }
          }
        }
      }
    }
  }
}

.unauthorizePageContainer {
  background-repeat: no-repeat;
  position: relative;
  margin: 0;
  border: 0;
  outline: 0;
  height: 100vh;
  background-color: #f6f8f9;
  overflow:hidden;
}
.unauthorizePageContainer::before {
  background-image: url(https://themexriver.com/appilo-theme/saas-classic-dark/wp-content/themes/appilo/img/saas-classic/banner/b-shape1.png);
  right: 0;
  width: 100%;
  content: '';
  top: 2%;
  height: 100vh;
  position: absolute;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 90%;
}

.circleContainer {
  position: absolute;
  right: 250px;
  top: 0;
  max-width: 500px;
  animation: 7s apldg_rotate_animation ease-in-out infinite;
  animation-direction: alternate;
}

.shape2Container {
  img{
    max-width: 616px;
    opacity: 0.5;
  }
  position: absolute;
  left: -300px;
  bottom: -750px;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.shape3Container {
  position: absolute;
  left: 10%;
  bottom: 20%;
  animation: 20s apldg_move_animation linear infinite;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.shape4Container {
  position: absolute;
  right: -25%;
  bottom: 40%;
  animation: 5s apldg_rotate_animation2 linear infinite;
  animation-direction: alternate;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 0.3;
  img{
   max-width: 600px;
  }
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 20%;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  height: 500px;
  text-align: center;
  @media (max-width: 1280px) {
    top: 40%;
    left: 10%;
  }
  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 20%;
    left: 0;
    max-width: 100%;
  }
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.header {
  color: #4F5366;
  font-size: 50px;
  margin: 40px 0;
  @media (max-width: 1024px) {
    font-size: 45px;
  }
  @media (max-width: 768px) {
    font-size: 40px;
  }
  @media (max-width: 550px) {
    font-size: 35px;
  }
  @media (max-width: 320px) {
    font-size: 25px;
  }
}

.text {
  color: #2a1737;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 550px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    max-width: 95%;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.button {
  background: #bd68f8;
  border: none;
  text-decoration: none;
  color:white;
  background-color: $primaryColor;
  padding: 16px 24px;
  font-size: 12px;
  border-radius: 99px;
  max-width: 100px;
  width:100%;
  text-align: center;
}

.btnContainer {
  margin-top: 50px;
}

.projectManagementbgImg1{
  position: absolute;
  bottom: 54px;
  left: 0;
  z-index: 1;
  filter: brightness(1000%);
  width: 206px;
  @media (max-width: 1460px) {
    display: none;
  }
}

.projectManagementbgImg2{
  position: absolute;
  top: 48px;
  right: 0;
  transform: rotate(180deg);
  z-index: 1;
  filter: brightness(1000%);
  width: 211px;
  @media (max-width: 1460px) {
    display: none;
  }
}

@-webkit-keyframes apldg_rotate_animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes apldg_rotate_animation {
  from {
    right: 250px;
  }
  to {
    right: 350px;
  }
}

@keyframes apldg_rotate_animation2 {
  from {
    bottom: 20%;
  }
  to {
    bottom: 30%;
  }
}

@-webkit-keyframes apldg_move_animation {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(200px, 100px) rotate(90deg);
    transform: translate(200px, 100px) rotate(90deg);
  }
  50% {
    -webkit-transform: transalate(600px, 200px) rotate(180deg);
    transform: transalate(600px, 200px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50px) rotate(270deg);
    transform: translate(-50px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(360deg);
    transform: translate(0) rotate(360deg);
  }
}

@keyframes apldg_move_animation {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(200px, 100px) rotate(90deg);
    transform: translate(200px, 100px) rotate(90deg);
  }
  50% {
    -webkit-transform: transalate(600px, 200px) rotate(180deg);
    transform: transalate(600px, 200px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50px) rotate(270deg);
    transform: translate(-50px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(360deg);
    transform: translate(0) rotate(360deg);
  }
}

.colorMainTheme {
  color:$primaryColor
}

.maxWidth80 {
  max-width: 80%;
  margin: auto;
}



.bgImg2 {
  position: absolute;
  bottom: -300px;
  left: 0;
  z-index: -1;
}

.bgImg3 {
  position: absolute;
  bottom: -550px;
  right: 0;
  z-index: -1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.container {
  max-width: 1280px;
  @media (max-width: 1280px) {
    max-width: 1000px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.projectManagement {
  padding: 100px 0;
  max-width: 1000px;
  width:100%;
  position: relative;
}

.colorWhite {
  color: white !important;
}


.businessContent{
  max-width: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
  h3{
    font-size: 22px!important;
    color:$textPrimaryColor!important;
    font-weight: 600!important;
    margin: 0;
  }

  .jobDoneParagraph{
    color:#9191A1;
    padding: 0!important;
    margin: 0!important;
    ul{ 
      padding: 0;
      
    }
    li{
      margin: 12px 0;
      display: flex;

      svg{
        font-size: 20px;
        margin-right: 10px;
        fill:$textPrimaryColor
      }
    }
  }
}

.eyebrow {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0;
  color: $textPrimaryColor;
  font-weight: 600
}        

.eyebrowIcon{
    font-size: 30px !important;
}

.link{
  color:$primaryColor;
  font-size: 13px;
  width: 100%;
  margin-left:20px;
}

.links{
  max-width: 417px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}


.supportPicture{
  img{
    max-width: 400px;
  }
}