@use '../../variables.scss' as *;

.section {
  overflow: hidden;
  padding: 6rem 0;
  @media (max-width: 420px) {
    padding: 2rem 0;
  }
  .container {
    margin: 0 auto;
    width: 95%;
    max-width: 1220px;
    display: flex;
    align-items: center;
    gap: 2rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .content {
      text-align: left;
      flex: 1 2 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      @media (max-width: 768px) {
        align-items: center;
      }
      @media (max-width: 420px) {
        gap: 1rem;
      }
      .header__before {
        font-size: 12px;
        font-weight: bold;
        color: $primaryColor;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .header {
        margin: 0;
        color: #4f5366;
        font-size: 50px;
        @media (max-width: 768px) {
          text-align: center;
          font-size: clamp(1.5rem, 7vw, 3rem);
        }
        &::after {
          display: block;
          max-width: 100px;
          height: 5px;
          border-radius: 7px;
          content: '';
          margin-top: 2rem;
          background: linear-gradient(90deg, $primaryColor 100%, #7b68ee 0);
          @media (max-width: 768px) {
            margin: 2rem auto 0;
          }
          @media (max-width: 420px) {
            margin: 1rem auto 0;
          }
        }
      }
      .text {
        color: #7c828d;
        line-height: 1.5;
        letter-spacing: 0.05em;
        @media (max-width: 420px) {
          text-align: center;
        }
      }
    }
    .demo {
      position: relative;
      align-self: flex-start;
      flex: 1 1 auto;
      aspect-ratio: 1.6;
      display: block;
      padding: 2rem 3rem;
      margin: auto;
      background-image: url('../../assets/bg__with-dotted.svg');
      @media (max-width: 768px) {
        padding: 0;
      }
      .diamondShape {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        transform: translate(50%, -50%);
        width: clamp(300px, 50vw, 500px);
        aspect-ratio: 1;
        @media (max-width: 420px) {
          display: none;
        }
      }
    }
  }
}

.section.section__right {
  .container {
    flex-direction: row-reverse;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .demo {
      .diamondShape {
        left: 0;
        transform: translate(-30%, -50%);
      }
    }
  }
}
