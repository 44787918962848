.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(9, 30, 66, 0.54);
  box-sizing: border-box;
  z-index: 100;
  margin: auto;
}

.container {
  height: 90vh;
  @media (max-width: 280px) {
    height: 25%;
  }
  width: 65%;
  min-width: 350px;
  margin: auto;
  margin-top: 3%;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  overflow-y: scroll;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 31%) 0px 0px 20px -6px;
  color: rgb(9, 30, 66);
  .cardTitle {
    margin-left: 30px;
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    margin: 30px;
  }
}

.container::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
}

.container::-webkit-scrollbar:hover {
  width: 8px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
