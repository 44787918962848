@use '../../variables.scss' as *;

$gray: rgb(246, 248, 249);

.pageContainer {
  display: flex;
  min-height: 100vh;
  .sectionContainer {
    width: 100%;
    padding: 2rem;
    .sectionTitle {
      margin-bottom: 2rem;
    }

    .mainColumn {
      flex: 2;
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .creditCards__container {
        background-color: $gray;
        border-radius: 5px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .creditCardImg {
          max-width: 400px;
        }
      }
    }

    .sideColumn {
      flex: 1;
      align-self: start;
      padding: 1rem;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 1px 0px rgb(8 30 66 / 8%), 0px 2px 5px 1px rgb(8 30 66 / 8%);

      .sideColumn__main {
        padding: 1em 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-bottom: 1px solid #dee1e6;
        .planBtn {
          padding: 5px 8px;
          margin-left: -8px;
          border-radius: 3px;
          font-size: 14px;
          color: #006644;
          text-decoration: none;
          align-self: start;
          transition: 0.2s;
          &:hover {
            background-color: #e3fcef;
          }
        }
        .trialBtn {
          background-color: #e3fcef;
          font-weight: bold;
        }
      }
      .sideColumn__footer {
        padding-top: 1em;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .totalPrice {
          font-size: 1.5rem;
          font-weight: bold;
          color: black;
        }
      }
    }
  }
}

.billingContactTitle {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  h4 {
    margin-right: 0.5rem;
  }
  .tooltip {
    position: relative;
    display: inline-block;
    font-size: 12px;
    &:hover {
      .tooltip__pop {
        opacity: 1;
        visibility: visible;
      }
    }
    .tooltip__pop {
      position: absolute;
      top: 0;
      transform: translateY(calc(-100% - 0.5em));
      width: 250px;
      padding: 1em;
      opacity: 0;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      gap: 1em;
      background-color: white;
      border-radius: 3px;
      box-shadow: 0px 0px 1px 0px rgb(8 30 66 / 8%), 0px 2px 5px 1px rgb(8 30 66 / 8%);
      transition: 0.5s linear;
      p {
        line-height: 1.3;
        text-align: justify;
      }
    }
  }
}

.textSecondary {
  font-size: 14px;
  color: gray;
}

.pageBtn {
  background-color: $primaryColor;
  color: white;
  font-weight: bold;
  padding: 0.5em 0.8em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: $primaryColor-hover;
  }
}

.flexRow {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colorGray {
  color: gray;
}

.flexAlign {
  display: flex;
  align-items: center;
}

.userIcon {
  background-color: gray;
  border-radius: 50%;
  padding: 4px;
  margin-right: 0.5rem;
}

.infoIcon {
  color: white;
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 1rem;
  aspect-ratio: 1;
  background-color: gray;
  cursor: pointer;
}
