.imagePart {
  position: relative;
  width: 58%;
  overflow: hidden;
  padding: 20px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url(https://clickup.com/images/collaboration-detection/bg__with-dotted.svg)
      no-repeat top left/cover;
  }
}

@media screen and (max-width: 768px) {
  .imagePart {
    width: 100%;
  }
}
