.thumbnailImage {
  height: 60px;
  max-width: 120px;
  cursor: pointer;
  animation: all 9s infinite linear;
}
.thumbnailImageContainer::after {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.removeButton {
  width: 70px;
  text-align: left;
  margin-left: 4px;
  border-width: 0px;
  border-radius: 3px;
  font-size: 14px;
  text-decoration: underline;
  font-style: normal;
  font-family: inherit;
  font-weight: 600;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;
  background: none;
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  padding: 0px 6px;
  justify-content: center;
  color: var(--ds-text, #42526e);
}
.removeButton:hover {
  background-color: #efefef;
}
.removeButton:active {
  color: #1d0fe4 !important;
  background-color: #a4b5ed;
  filter: brightness(1.2);
}

.thumbnailImageContainer {
  position: relative;
  width: max-content;
  background: transparent;
  border: none;
  cursor: pointer;
}

.thumbnailImageContainer:hover::before {
  animation: color 0.25s forwards linear;
  opacity: 0;
  content: 'View';
  color: white;
  position: absolute;
  top: 32px;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.thumbnailImageContainer:hover::after {
  animation: color 0.25s forwards linear;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.photoContainer {
  margin: 20px 0;
  max-height: 100px;
  overflow: hidden;
  display: flex;
}

.imageContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.fullImage {
  top: 0;
  left: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 90%;
}

.closeBtn {
  color: #dce3f0;
  width: 40px;
  height: 40px;

  path {
    color: #dce3f0 !important;
  }
}

.backgroundNone {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  cursor: pointer;
}

@keyframes color {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
}
