@use '../../variables.scss' as *;

.sectionContainer {
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .paymentImg {
    display: block;
    height: 40vh;
    aspect-ratio: 1;
    max-width: 350px;
    min-height: 200px;
  }
  .contents {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    max-width: 400px;
    p {
      line-height: 1.5;
      text-align: justify;
    }
  }
}

.textSecondary {
  font-size: 14px;
  color: gray;
}

.linkBtn {
  background-color: $primaryColor;
  color: white;
  font-weight: bold;
  padding: 0.5em 0.8em;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    background-color: $primaryColor-hover;
  }
}
