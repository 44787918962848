@use '../../variables.scss' as *;

$gray: rgb(246, 248, 249);

.pageContainer {
  display: flex;
  min-height: 100vh;
  .sectionContainer {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .rowLayout {
      display: flex;
      gap: 2rem;
      .mainColumn {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .warnBox {
          background-color: #fffae6;
          border-radius: 5px;
          padding: 1rem;
          display: flex;
          gap: 1.5rem;
        }
        .subscriptionsBox {
          background-color: $gray;
          padding: 1rem;
          border-radius: 5px;
          .subscriptionsBoxTitle {
            font-size: 14px;
            color: gray;
            line-height: 2;
            margin-top: -14px;
          }
          .planBox {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .planTitle {
              display: flex;
              align-items: center;
              .logoIcon {
                width: 2em;
                aspect-ratio: 1;
              }
              .label {
                font-weight: 500;
                padding: 3px 5px;
                margin-left: 8px;
                border-radius: 3px;
                font-size: 12px;
                color: #006644;
                background-color: #e3fcef;
              }
            }
            .planEndDate {
              align-self: flex-end;
            }
            .planFooter {
              border-top: 2px solid rgb(128, 128, 128, 0.3);
              display: flex;
              align-items: stretch;
              padding-top: 1rem;
              justify-content: flex-end;
              .trialBtn {
                text-decoration: none;
                font-size: 14px;
                color: gray;
                background-color: $gray;
                padding: 5px 8px;
                border-radius: 3px;
                margin-right: 0.5rem;
                &:hover {
                  background-color: rgb(128, 128, 128, 0.3);
                }
              }
              .planOptionsBtn {
                position: relative;
                .dotsBtn {
                  border: none;
                  font-size: 1rem;
                  font-weight: bold;
                  padding: 0 8px 7px;
                  background-color: $gray;
                  border-radius: 3px;
                  cursor: pointer;
                  &:hover {
                    background-color: rgb(128, 128, 128, 0.3);
                  }
                }
                .planOptions {
                  list-style: none;
                  background-color: white;
                  position: absolute;
                  top: calc(100% + 0.25em);
                  right: 0;
                  display: none;
                  flex-direction: column;
                  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
                    rgb(9 30 66 / 31%) 0px 0px 20px -6px;
                  width: fit-content;
                  border-radius: 3px;
                  z-index: 3;
                  &.active {
                    display: flex;
                  }
                  .optionBtn {
                    border: none;
                    font-size: 12px;
                    padding: 10px 8px;
                    text-decoration: none;
                    white-space: nowrap;
                    display: block;
                    &:hover {
                      cursor: pointer;
                      background-color: #e0e0e0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .sideColumn {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .sideColumn__footer {
          border-top: 1px solid #dee1e6;
          padding-top: 1em;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .totalPrice {
            font-size: 1.5rem;
            font-weight: bold;
            color: black;
          }
        }
      }
    }
  }
}

.cardBox {
  padding: 1rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0px rgb(8 30 66 / 8%), 0px 2px 5px 1px rgb(8 30 66 / 8%);
}

.links {
  font-size: 14px;
  color: $primaryColor;
  text-decoration: none;
}

.textSecondary {
  font-size: 14px;
  color: gray;
}

.flexCol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
