.resetPasswordResultContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    img{
      max-width: 50%;
      height: auto;
      align-self: center;
    }
    h1{
      align-self: center;
    }
}
