@import './styles/variables.scss';

.mainWrapper {
  margin-top: $size-80 * 1.2; // For the floating top nav
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $size-50 * 3;
  overflow-x: hidden;

  @media screen and (max-width: 768px) {
    gap: $size-50;
  }

  &::before {
    background-image: url(https://themexriver.com/appilo-theme/saas-classic-dark/wp-content/themes/appilo/img/saas-classic/banner/b-shape1.png);
    right: 0;
    width: 100%;
    content: '';
    top: -$size-80 * 1.2;
    height: 100vh;
    position: absolute;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 90%;
  }
}

.shape3Container {
  position: absolute;
  left: 10%;
  top: 10%;
  animation: 20s apldg_move_animation linear infinite;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

@-webkit-keyframes apldg_move_animation {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(200px, 100px) rotate(90deg);
    transform: translate(200px, 100px) rotate(90deg);
  }
  50% {
    -webkit-transform: transalate(600px, 200px) rotate(180deg);
    transform: transalate(600px, 200px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50px) rotate(270deg);
    transform: translate(-50px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(360deg);
    transform: translate(0) rotate(360deg);
  }
}

@keyframes apldg_move_animation {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(200px, 100px) rotate(90deg);
    transform: translate(200px, 100px) rotate(90deg);
  }
  50% {
    -webkit-transform: transalate(600px, 200px) rotate(180deg);
    transform: transalate(600px, 200px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50px) rotate(270deg);
    transform: translate(-50px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(360deg);
    transform: translate(0) rotate(360deg);
  }
}
