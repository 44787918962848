.FAQHeader {
  background-color: rgb(28, 29, 55);
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .headerTitle {
    color: white;
    font-size: 40px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      text-align: center;
      margin: 0 30px 50px;
    }
  }
  .searchBar {
    width: 50%;
    height: 58px;
    font-size: 20px;
    outline: none;
    border-radius: 10px;
    background-image: url(https://support.teamwork.com/projects/public/images/icons/i-search@2x.png);
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding: 0 10px 0 57px;
  }
}
