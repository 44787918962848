.body {
  width: 100%;
}

.priceBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(79, 83, 102);
  line-height: 18.4px;
  width: 100%;
}