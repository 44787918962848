.userPageContainer {
  margin: auto;
  max-width: 20%;
  @media screen and (max-width: 2450px) {
    max-width: 20%;
  }
  @media screen and (max-width: 1600px) {
    max-width: 30%;
  }
  @media screen and (max-width: 1440px) {
    max-width: 35%;
  }
  @media screen and (max-width: 1200px) {
    max-width: 40%; 
  }
  @media screen and (max-width: 700px) {
    max-width: 60%; 
  }
  @media screen and (max-width: 480px) {
    max-width: 65%; 
  }
  @media screen and (max-width: 380px) {
    max-width: 70%; 
  }

  .userBar h2 {
    font-size: 1.71429em;
    font-style: inherit;
    color: #0052cc;
    font-weight: 600;
    letter-spacing: -0.01em;
    margin-top: 30px;
    margin-left: 30px;
  }

  .userForm {
    margin-top: 100px;
    width: 100%;
    height: 90%;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    box-sizing: border-box;
    color: rgb(94, 108, 132);
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
  }

  .userIconAndInfo{
    display: flex;
    flex-direction: row;
    padding-top: 15px;
  }
  .userInfo {
    width: 100%;
  }
  .userForm label {
    font-size: 12px;
    font-weight: 700;
  }
  .public {
    color: rgb(149, 145, 145);
    font-size: 12px;
    margin-right: 8px;
  }

  input {
    width: 70%;
    height: 35px;
    font-size: 12px;
    background-color: rgba(226, 221, 221, 0.28);
    border: none;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .proIcon {
    background-position: right;
    background-repeat: no-repeat;
    margin-top: 15px;
    border-radius: 5px;
  }
  textarea {
    min-width: 450px;
    max-width: 450px;
    height: 100px;
    margin-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.025);
    border: 2px solid rgba(189, 188, 188, 0.314);
  }

  .submit {
    background-color: #0052cc;
    border: none;
    color: white;
    font-size: 12px;
    width: 8em;
    padding: 2%;
    border-radius: 5px;
    cursor: pointer;
  }
  .userIcon {
    padding-left: 30px;
    padding-right: 45px;
  }
  .userIcon img {
    margin-top: 5px;
    width: 5.625em;
    height: 5.625em;
  }
  .iconPublic {
    padding-top: 10px;
    padding-left: 25px;
    color: rgb(149, 145, 145);
    font-size: 12px;
  }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
   
//////////////////////////////@at-root

.settingPage{
  display: flex;
}

.settingContainer{
    background-color: rgb(246, 248, 249);
    width: 100%;
}

.settingMiniContainer{
    max-width: 800px;
    margin: auto;
    width: 80%;
}


.divider{
    flex-shrink: 0;
    width: 100%;
    height: 1px;
    margin-top: 32px;
    background-color: rgb(229, 231, 239);
    opacity: 1;
    border:0
}

.headerText{
    display: inline-block;
    font-style: normal;
    font-stretch: normal;
    word-break: break-word;
    margin: 10px 0 0 0;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.5;
    color: rgb(79, 83, 102);
}

.gap{
    gap:50px
}

.row{
    margin: 24px 0;
}

.modal{
  padding: 30px;
  .modalBtn{
    display: flex;
    justify-content: flex-end;
    button{
        margin-left: 20px;
    }
  }
  p{
    margin: 20px 0 50px 0;
    font-size: 18px;
  }
}
.p{
  font-size: 14px;
  font-weight: bold;
  margin: 7px 0 20px 0px;
}