.reporter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  max-height: 35px;
  font-size: 16px;
  .leftContent {
    display: flex;
    align-items: center;
    .reactIcon {
      margin-right: 5px;
    }
  }

  & > div:nth-of-type(1) {
    box-sizing: border-box;
    width: 50%;
    line-height: 1;
    color: var(--ds-text-subtle, #38465e);
    font-weight: 500;
  }

  & > div:nth-of-type(2) {
    font-size: 16px;
    line-height: 1;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: background 0.2s ease 0s;
    color: var(--ds-text-subtlest, #5e6c84);
    font-weight: 500;
    align-items: center;
  }
  .leadDropdownContainer {
    position: relative;
    box-sizing: border-box;
    margin-top: 6px;
    .leadDropdownOpen {
      background-color: #ffffff;
      border-color: #4c9aff;
      align-items: stretch;
      border-radius: 3px;
      border-style: solid;
      border-width: 2px;
      box-shadow: none;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 44px;
      position: relative;
      box-sizing: border-box;
      padding: 0px;
      width: 100%;
      height: 44px;
      max-width: 100%;
      outline: 0px;
      .leadInputField {
        align-items: center;
        display: flex;
        flex: 1 1 0%;
        flex-flow: row nowrap;
        box-sizing: border-box;
        padding: 6px 6px 6px 6px;
        overflow: hidden;
        max-height: 100%;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
        .userAvatar {
          width: 24px;
          height: 24px;
          display: inline-block;
          position: relative;
          outline: 0px;
          margin: 2px;
          cursor: pointer;
        }
        span {
          display: block;
          margin-left: 7px;
          color: #091e42;
          min-width: 0px;
          max-width: 100%;
          flex: 1 1 100%;
          line-height: 1;
          border: none;
          cursor: pointer;
          outline: none;
          font-weight: 400;
        }
        .optionToggle {
          border: none;
          background-color: rgb(255, 255, 255);
          width: 30px;
          height: 30px;
          cursor: pointer;
          i {
            background-color: rgb(255, 255, 255);
            width: 24px;
            height: 24px;
          }
        }
      }
      .leadMenu {
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: 165px;
        overflow-y: scroll;
        z-index: 999;
        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          padding-left: 0;
          border: rgb(202, 200, 200);
          border-style: solid;
          border-radius: 3px;
          border-width: 1px;
          li {
            width: 100%;
            button {
              display: flex;
              align-items: center;
              height: 49px;
              padding-left: 11px;
              border: none;
              background-color: rgb(255, 255, 255);
              width: 100%;
              cursor: pointer;
              &:hover {
                border-style: solid;
                border-left-color: #4c9aff;
                border-right: none;
                border-top: none;
                border-bottom: none;
                background-color: rgb(244, 245, 247);
              }
              img {
                width: 31px;
                border-radius: 50%;
              }
              span {
                margin-left: 10px;
                display: flex;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                text-transform: none;
                flex: 1 1 100%;
                color: rgb(23, 43, 77);
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .leadInputClose {
      cursor: pointer;
      justify-content: space-between;
      min-height: 44px;
      padding: 0px;
      width: 100%;
      height: 44px;
      max-width: 100%;
      outline: 0px;
      align-items: center;
      display: flex;
      flex: 1 1 0%;
      overflow: hidden auto;
      max-height: 100%;
      position: relative;
      margin: 0;
      border: none;
      background-color: #fff;
      &:hover {
        background-color: rgb(235, 236, 240);
        border-color: rgb(223, 225, 230);
      }
      img {
        display: flex;
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }
      span {
        margin-left: 10px;
        display: flex;
        width: 100%;
        font-size: 14px;
        color: rgb(23, 43, 77);
        font-weight: 400;
      }
    }
  }
}
