.attachButton {
  align-items: center;
  border-width: 0px;
  border-radius: 3px;
  display: inline-flex;
  font-size: 15px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;
  background: var(--ds-background-neutral, rgba(9, 30, 66, 0.04));
  cursor: pointer;
  height: 2em;
  padding: 0px 10px;
  justify-content: center;
  outline: none;
  color: var(--ds-text, #42526e);
  margin-top: 12px;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      display: none;
    }
  }

  &:hover {
    background: rgba(9, 30, 66, 0.08);
  }

  &:active {
    color: #0052cc;
    background: rgba(179, 212, 255, 0.6);
  }
}
