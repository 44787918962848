@import "../../../variables.scss";

.dropDownList {
    display: flex;
    flex-wrap: wrap;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 5px 0 0 0;
    border-radius: 5px;
    max-height: 240px;
    overflow-y: scroll;
    top:0px;
    z-index: 101;
    position: absolute;
    width: 100%;
    button {
        width: 100%;
        line-height: 20px;
        background: transparent;
        border:none;
        text-align: left;
        padding: 9px 14px;
        font-weight: 300;
        font-size: 14px;
        color: rgb(165, 168, 177);
        &:hover{
            background: rgb(246, 248, 249);
            font-weight: 900;
            color:rgb(79, 83, 102);
            border-left:3px solid $primaryColor;
            border-right: 3px solid $primaryColor;
        }
    }
}
.dropDownList::-webkit-scrollbar{
    width: 0px;
    border-radius: 5px;
}

.dropDownList::-webkit-scrollbar-thumb{
    background: rgb(219, 220, 225);
    border-radius: 5px;
}

.dropDown{
    position: absolute;
    right: 5px;
    bottom:25%;
    font-size:20px;
}

.placeHolder{
 color:rgb(219, 220, 225);
 margin:0;
 padding:0;
}

.val{
    margin:0;
    padding:0;
}