.BacklogFilterArea {
  margin-left: 20px;
  display: flex;

  .backlogUser {
    z-index: 3;
    .backlogUserIconButton {
      background: none;
      border: none;

      .backlogUserIconContainer {
        position: relative;
      }

      .backlogUserIconContainer:hover:after {
        position: absolute;
        content: attr(data-tooltip);
        background-color: #172b4d;
        color: white;
        padding: 3px;
        border-radius: 5px;
        left: 50%;
        top: 35px;
        transform: translateX(-50%);
      }

      .backlogUserIcon {
        height: 30px;
        border-radius: 100%;
        margin: 2px;
      }

      .backlogUserIcon:hover {
        cursor: pointer;
      }

      .backlogUserIconWithBorder {
        height: 30px;
        border-radius: 100%;
        margin: 2px;
        box-shadow: 0 0 0 2px #0052cc;
      }

      .backlogUserIconWithBorder:hover {
        cursor: pointer;
      }

      .backlogRestUsers {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: #dfe1e6;
        color: #7a869a;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        margin: 2px;
      }

      .backlogRestUsers:hover {
        cursor: pointer;
      }
    }

    .backlogRestUsersDropdownContainer {
      position: absolute;
      box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
        rgb(9 30 66 / 31%) 0px 0px 20px -6px;
      max-width: 300px;
      margin-top: 5px;

      .backlogRestUsersSelectButton {
        border: none;
        background-color: white;
        width: 100%;

        .backlogRestUsersDropdownDetailContainer {
          display: flex;
          align-items: center;
          padding: 5px;
          .backlogCheckBoxIcon {
            font-size: 20px;
          }
          .backlogRestUsersDropdownAvatar {
            height: 25px;
            border-radius: 100%;
            margin-left: 10px;
          }
          .backlogRestUsersDropdownName {
            margin-left: 10px;
          }
        }
      }

      .backlogRestUsersSelectButton:hover {
        cursor: pointer;
        background-color: #dfe1e6;
      }
    }
  }
}
