$max_size: 768px;
$small_size: 1000px;

nav.navNormal {
  top: 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  text-decoration: none;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
  z-index: 2000;
  min-height: 50px;
  max-height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.05s ease-in-out, box-shadow 0.15s ease-in-out,
    max-height 0.3s ease-in-out;
  @media (max-width: $max_size) {
    max-width: 100%;
    min-height: 50px;
    max-height: 80px;
    overflow: hidden;
  }
  .container {
    align-self: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;

    @media (max-width: 1250px) {
      max-width: 1120px;
    }

    @media (max-width: 1150px) {
      max-width: 1000px;
    }

    @media (max-width: 1020px) {
      max-width: 900px;
    }

    @media (max-width: 920px) {
      max-width: 760px;
    }

    @media (max-width: $max_size) {
      flex-direction: column;
      max-height: auto;
    }

    img {
      max-width: 140px;
      max-height: 50px;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    .logo {
      max-width: 140px;
    }
    .space {
      flex: 1;
      @media (max-width: $max_size) {
        display: none;
      }
    }
    .functionsLoginTabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 14px;
      @media (max-width: $max_size) {
        flex-direction: column;
        margin-top: 14px;
      }
      a {
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        display: inline-flex;
        cursor: pointer;
        color: #1d1c39;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.2s ease-in-out;
        text-decoration: none;

        @media (max-width: $max_size) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.058823529411764705);
          padding: 15px 5px;
          font-size: 15px;
        }
      }
      a.btn {
        border-radius: 32px;
        background: #6a84ed;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.75px;
        padding: 16px 24px;
        color: #fff;
        @media (max-width: $max_size) {
          display: flex;
          justify-content: center;
        }
      }
    }
    .menuBar {
      display: none;
      @media (max-width: $max_size) {
        display: flex;
        float: right;
        position: absolute;
        top: 31px;
        right: 21px;
      }
      svg {
        max-width: 30px;
        max-height: 30px;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .burBackground {
    display: none;
    z-index: 50;
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(1rem);
  }
}

nav.navActive {
  @media (max-width: $max-size) {
    max-width: unset;
    width: 100%;
    min-height: none;
    max-height: none;
    height: 100vh;
    overflow-y: auto;
  }
}
