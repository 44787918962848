.InviteMemberFloatFormContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(9, 30, 66, 0.54);

  form {
    margin-top: 15%;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
      rgb(9 30 66 / 31%) 0px 0px 20px -6px;
    color: rgb(9, 30, 66);
    flex-direction: column;
    display: flex;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 40%;
    @media (max-width: 1200px){
      max-width: 50%;
    }
    @media (max-width: 800px){
      max-width: 60%;
    }
    @media (max-width: 400px){
      max-width: 80%;
    }
    padding: 2%;

    h1 {
      line-height: 1.1034482759;
      color: var(--ds-text-highEmphasis, var(--ds-text-highEmphasis, #172b4d));
      font-weight: 600;
      letter-spacing: -0.01em;
    }

    div{
        margin: 5px;
    }

    .content {
      display: flex;
      flex-direction: column;
      p {
        font-size: 0.857143em;
        font-style: inherit;
        line-height: 1.33333;
        color: var(--ds-text-subtlest, var(--ds-text-subtlest, #6b778c));
        font-weight: 600;
        display: inline-block;
        margin-top: 0px;
        margin-bottom: 4px;

      }

      input {
        align-items: center;
        background-color: var(--ds-background-input, #fafbfc);
        border-color: var(--ds-border, #dfe1e6);
        color: var(--ds-text, #091e42);
        cursor: text;
        border-radius: 3px;
        border-width: 2px;
        border-style: solid;
        box-sizing: border-box;
        font-size: 14px;
        max-width: 100%;
        overflow: hidden;
        transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
        overflow-wrap: break-word;
        pointer-events: auto;
        padding: 8px 6px;
      }

      select{
        align-items: center;
        background-color: var(--ds-background-input, #fafbfc);
        border-color: var(--ds-border, #dfe1e6);
        color: var(--ds-text, #091e42);
        cursor: pointer;
        border-radius: 3px;
        border-width: 2px;
        border-style: solid;
        box-sizing: border-box;
        font-size: 14px;
        max-width: 100%;
        overflow: hidden;
        transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
        overflow-wrap: break-word;
        pointer-events: auto;
        padding: 8px 6px;
      }
    }

    .buttonList {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button {
        margin: 3px;
        align-items: baseline;
        border-width: 0px;
        border-radius: 3px;
        box-sizing: border-box;
        display: inline-flex;
        font-size: 14px;
        font-style: normal;
        font-family: inherit;
        font-weight: 500;
        max-width: 100%;
        position: relative;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        background: #0052cc;
        cursor: pointer;
        height: 2.28571em;
        line-height: 2.28571em;
        padding: 0px 10px;
        vertical-align: middle;
        width: auto;
        justify-content: center;
        color: #ffffff;
      }
    }
  }
}
