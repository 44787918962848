:root {
  --topNavigationHeight: 56px;
  --leftSidebarWidth: 240px;
  --leftSidebarFlyoutWidth: 240px;
}

.container {
  padding: 4rem;
  max-width: 50%;
  color: var(--ds-text-highEmphasis, #172b4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
}

.container > :first-of-type {
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  width: calc(
    100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) -
      var(--rightSidebarWidth, 0px)
  ) !important;
}
