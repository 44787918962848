@use '../../../variables.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600&display=swap');

.formContainer {
  position: relative;
  overflow: hidden;
  background: transparent;
  border-radius: 12px;
  width: 100%;
  padding: 2rem 3rem;
  max-width: 513px;
  @media (max-width: 1279px) {
    max-width: 450px;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 30px;
  }
  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: 'Work Sans', sans-serif;
    select,
    button,
    input {
      font-family: inherit;
    }
    select {
      width: 100%;
    }

    .inputField {
      padding: 1rem;
      background-color: #f7f7f7;
      border-radius: 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &:hover {
        border-color: $primaryColor;
      }
      select {
        font-size: 1rem;
        padding: 0.5rem 0;
        border: none;
        background-color: transparent;
        outline: none;
      }
    }

    button {
      border-radius: 0.5rem;
      color: #fff;
      background: $primaryColor;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.75px;
      padding: 16px 24px;
      width: 100%;
      align-items: center;
      border: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      &:hover {
        outline: none;
        box-shadow: inset 0 0 0 100px rgb(0 0 0 / 30%);
      }
    }
    .desc {
      margin: 0;
      color: #727491;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 145%;
      font-weight: 400;
      .link {
        color: $primaryColor;
        margin: 0;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0.25px;
      }
    }
    .errMsg {
      text-align: center;
      margin: 0 1rem;
      line-height: 1.5;
      font-size: 0.75rem;
      color: red;
    }
    .loading {
      text-align: center;
      margin: 0 1rem;
      line-height: 1.5;
      font-size: 0.75rem;
      color: $primaryColor;
    }
  }
}

.close {
  display: flex;
  justify-content: flex-end;
  margin: 0.5em;
}

.close:hover {
  cursor: pointer;
}

.modalPadding {
  padding: 30px 80px 80px 80px;
}

.iconPosition {
  text-align: center;
}

.messagePosition {
  text-align: center;
}
