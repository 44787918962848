.addBtn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .notice-container {
    position: absolute;
    left: -144px;
    background-color: #495057;
    border-radius: 4px;
    box-shadow: 0 2.4rem 4.8rem rgb(0 0 0 / 8%);
    padding: 0px 5px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 800;
    font-size: 4px;
    z-index: 10;
    display: block;
  }

  .icon {
    color: #ffffff;
  }

  .addBtn {
    margin: 3px;
    align-items: center;
    border-width: 0px;
    border-radius: 6px;
    display: inline-flex;
    background: #0052cc;
    cursor: pointer;
    height: 32px;
    padding: 0px 10px;
    color: #ffffff;
    transition: all 0.3s;
  }

  .addBtn:hover {
    background-color: #0042a3;
  }
}
