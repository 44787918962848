.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 30px;
  @media (max-width: 1130px) {
    display: none;
  } 
}

.table {

  th {
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }

  td {
    text-align: center;
  }

}