$maxSize: 1200px;
$minSize:704px;

.resetPasswordContainer {
  background-image: url(./default_left.svg), url(./default_right.svg);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-size: 368px, 368px;
  background-position: left bottom, right bottom;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgb(250, 251, 252);

  @media(max-width: $maxSize){
    background-size: calc(((100vw - 400px) / 2) - 32px), calc(((100vw - 400px) / 2) - 32px), cover;
  }

  @media(max-width: $minSize){
    display:none;
  }
}


.unauthorizePageContainer{
  background-image: url(https://themexriver.com/appilo-theme/seo-agency/wp-content/uploads/sites/56/2021/11/hero-bg.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center center;
  margin: 0;
  border: 0;
  outline: 0;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
}

.circleContainer{
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 800px;
  z-index: -1;
  @media (max-width: 1000px) {
    max-width: unset;
    width: 25%;
    max-height: unset;
  }
}

.shape2Container{
  position: absolute;
  left: 25%;
  top: 25%;
  animation: 15s apldg_rotate_animation linear infinite;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  z-index: -1;
}

.shape3Container{
  position: absolute;
  right: 40%;
  bottom: 30%;
  animation: 20s apldg_move_animation linear infinite;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  z-index: -1;
}

.textContainer{
  position: fixed;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 500px;
  text-align: center;
}

.header{
  color:#894bb5;
  font-size: 100px;
  margin: 40px 0 ;
}

.text{
  color:#2a1737;
  font-size: 20px;
}

.button{
  border-radius: 5px;
  background: #bd68f8;
  border:none;
  padding: 10px 30px;
  color:white;
  text-decoration: none;
}

.btnContainer{
  margin-top: 50px;
}




@-webkit-keyframes apldg_rotate_animation {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes apldg_rotate_animation {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-webkit-keyframes apldg_move_animation {
  0% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
  }
  25% {
      -webkit-transform: translate(200px, 100px) rotate(90deg);
      transform: translate(200px, 100px) rotate(90deg);
  }
  50% {
      -webkit-transform: transalate(600px, 200px) rotate(180deg);
      transform: transalate(600px, 200px) rotate(180deg);
  }
  75% {
      -webkit-transform: translate(-50px) rotate(270deg);
      transform: translate(-50px) rotate(270deg);
  }
  100% {
      -webkit-transform: translate(0) rotate(360deg);
      transform: translate(0) rotate(360deg);
  }
}

@keyframes apldg_move_animation {
  0% {
      -webkit-transform: translate(0) rotate(0deg);
      transform: translate(0) rotate(0deg);
  }
  25% {
      -webkit-transform: translate(200px, 100px) rotate(90deg);
      transform: translate(200px, 100px) rotate(90deg);
  }
  50% {
      -webkit-transform: transalate(600px, 200px) rotate(180deg);
      transform: transalate(600px, 200px) rotate(180deg);
  }
  75% {
      -webkit-transform: translate(-50px) rotate(270deg);
      transform: translate(-50px) rotate(270deg);
  }
  100% {
      -webkit-transform: translate(0) rotate(360deg);
      transform: translate(0) rotate(360deg);
  }
}
