.outter-container {
  position: relative;
  .indicator-container {
    border-radius: 50%;
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    color: white;
    text-transform: uppercase;
  }
  .notice-container {
    position: absolute;
    left: 35px;
    top: 0px;
    background-color: #495057;
    border-radius: 4px;
    box-shadow: 0 2.4rem 4.8rem rgb(0 0 0 / 8%);
    padding: 0px 5px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 800;
    font-size: 10px;
    z-index: 8;
    display: block;
  }
}
