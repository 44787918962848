@import '../../../variables.scss';

.icon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  img {
    border-radius: 3px;
    width: 128px;
    height: 128px;
    margin: 0px auto;
  }
  button {
    margin-top: 16px;
    border-radius: 3px;
    background-color: white;
    border-color: #0052cc;
    border-width: 1.5px;
    color: #0052cc;
    cursor: pointer;
    height: 3.45em;
    line-height: 2.28571em;
    padding: 2px;
    font-family: inherit;
    font-size: 8px;
    font-weight: 700;
    width: 100%;
    &:hover {
      background: rgba(9, 30, 66, 0.08);
      text-decoration: inherit;
      transition-duration: 0s, 0.15s;
      color: #42526e;
    }
  }
}

.changeIconContainer{
  display: flex;
}

.profileImg{
  height: 100px;
  max-height: 100px;
}

.uploadImgBtn{
  background: none;
  color:$primaryColor;
  border:none;
  font-weight: 400;
  margin-left: 25px;
}

.uploadImgBtn:hover{
  cursor: pointer;
}

.skeletonImg{
  width: 100px;
  height: 100px;
  background: #e9e9e9;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
      opacity: (1);
  }
  100% {
      opacity: (0.5);
  }
}