.container {
  display: flex;
  flex-direction: column;
  margin: 16px;
  padding: 20px 36px 16px 36px;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
    rgb(9 30 66 / 31%) 0px 0px 20px -6px;

  .teamTitle {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    font-size: 48px;
    margin-bottom: 48px;
    color: rgb(1, 1, 1);
  }
  section h2 {
    font-size: 21px;
    color: rgb(1, 1, 1);
  }
  .memberLayout {
    display: flex;
    flex-wrap: wrap;
    padding: 0 32px 32px 32px;
    justify-content: flex-start;
  }
}
