.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0 0;
  .header {
    margin: 0 0 0 30px;
  }
}

.scrollContainer {
  flex: 1;
  padding-bottom: 3rem;
  padding-left: 20px;
}

.BacklogSearchFilter {
  display: flex;
  align-items: center;
  margin: 25px 0 35px 0;

  .BacklogSearchArea {
    position: relative;
    padding-right: 10px;

    .BacklogSearchBar {
      background-color: #dfe1e6;
      border: 0;
      height: 30px;
      border-radius: 5px;
    }

    .BacklogSearchIcon {
      position: absolute;
      right: 12px;
      top: 8px;
    }
  }
}
