.container {
  .description {
    font-size: 16px;
    font-weight: 600;
    margin-top: 24px;
    line-height: 24px;
    color: var(--ds-text, #172b4d);
    overflow-wrap: break-word;
  }

  textarea {
    border: none;
    border-radius: 3px;
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
      color: #565e6f;
      opacity: 1;
      font-size: 16px;
    }

    &:hover {
      border-color: transparent;
      background-color: rgb(9 30 66 / 10%);
    }

    &:focus {
      border-color: transparent;
      background-color: white;
    }
  }

  textarea::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  textarea::-webkit-scrollbar:hover {
    width: 5px;
    background-color: #f5f5f5;
    border-radius: 3px;
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  textarea::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
