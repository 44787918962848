.main-container {
  padding: 0 5%;
  margin-top: 100px;
  min-width: 800px;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.page-container::before {
  background: hsla(0,0%,100%,.9);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: .3s;
  width: 100%;
  z-index: 9;
}

.active::before {
  opacity: 1;
  pointer-events: auto;
}
