.rightContent {
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    align-items: center;
    letter-spacing: -0.01em;
    color: #3d5278;
  }
}
.passwordInput {
  width: 386px;
  height: 56px;
  background: #f6f7f9;
  border-radius: 10px;
  border: none;
  padding-left: 32px;
}
