$btHoverBg: rgb(245, 245, 247);

.container {
  position: relative;
  font-family: inherit;
  font-size: 15px;
  padding: 0 1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  img {
    width: 1.05em;
    aspect-ratio: 1;
  }
  .currentIcon {
    display: block;
  }
  .options {
    position: absolute;
    margin: 0;
    padding: 0;
    display: none;
    max-height: 15em;
    overflow-y: auto;
    border: 0.05em solid $btHoverBg;
    border-radius: 0.25em;
    width: 8em;
    left: 0;
    top: calc(100% + 0.25em);
    background-color: white;
    z-index: 100;
    &.show {
      display: flex;
      flex-direction: column
    }
    .option {
      border:none;
      padding: 0.25em 0.5em;
      background-color: transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: $btHoverBg;
      }
      .name {
        margin-left: 0.5em;
      }
    }
  }
}
