@import '../../../variables.scss';
$white: rgb(245, 245, 245);

.navBtn {
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    text-decoration: inherit;
    outline: 0px;
    border: 0px;
    font-size: 15px;
    width: 100%;
    height: 50px;
    color: $white;
    margin: 10px 0;
    cursor: pointer;
    background-color: transparent;
    font-weight: 500;
    padding: 8px 14px;
    position: relative;
    align-items: center;
    svg,
    img {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }

    .pencil {
      right: 0;
      position: absolute;
      border-width: 0px;
      border-radius: 10px;
      box-sizing: border-box;
      font-weight: 500;
      text-decoration: none;
      white-space: nowrap;
      background: none;
      cursor: pointer;
      padding: 0px;

      .pencilLine {
        width: 22px;
        height: 22px;
        display: none;
        color: $white;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      .pencilLine {
        display: block;
      }
    }
}