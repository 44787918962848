
.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 33%;
  box-sizing: border-box;
  min-width: 366px;
  padding: 0 68px;
  margin-top: 36px;

  @media (max-width: 1266px) {
    width: 45%;
  }

  @media (max-width: 980px) {
    width: 90%;
    justify-content: center;
    margin-bottom: 32px;
  }

  @media (max-width: 517px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0px;
    margin-left: 10px;
  }

  img {
    box-sizing: border-box;
    border-radius: 50%;
    width: 100px;
    border: 2px solid rgb(44, 72, 243);
    margin-right: 32px;
    min-width: 102px;
    height: 102px;

    @media (max-width: 1266px) {
      margin-right: 32px;
    }

    @media (max-width: 980px) {
      margin-right: 32px;
    }

    @media (max-width: 517px) {
      margin-bottom: 16px;
    }
  }

  .memberContent {
    .name {
      margin: 0;
      margin-bottom: 10.5px;
      color: rgb(1, 1, 1);
    }
    .position {
      color: white;
      background: rgb(44, 72, 243);
      border-radius: 3px;
      padding: 3.5px 8px;
    }

    ul {
      padding: 0;
      margin: 16px 0 0 16px;
      color: rgb(78, 78, 78);

      li span {
        margin-left: 10px;
      }
      li {
        margin-top: 6px;
      }

      li:nth-child(3) {
        margin-bottom: 18px;
      }
    }

    .LinkLayout {
      display: flex;
      align-items: center;
      word-break: break-all;

      .bsLinkedin {
        width: 19px;
        height: 19px;
        min-width: 19px;
        min-height: 19px;
        color: #0a66c2;
      }

      a {
        margin-left: 10px;
      }
    }
  }
}
