.projectMemberHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    font-size: 1.71429em;
    font-style: inherit;
    color: #172b4d;
    font-weight: 500;
    letter-spacing: -0.01em;
    margin-top: 0px;
    line-height: 32px;
    outline: none;
    white-space: nowrap;
    min-width: 0px;
    max-width: 100%;
    margin-bottom: 8px;
    flex: 1 1 auto;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin: 3px;
      align-items: baseline;
      border-width: 0px;
      border-radius: 3px;
      box-sizing: border-box;
      display: inline-flex;
      font-size: 14px;
      font-style: normal;
      font-family: inherit;
      font-weight: 500;
      max-width: 100%;
      position: relative;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      background: #0052cc;
      cursor: pointer;
      height: 2.28571em;
      line-height: 2.28571em;
      padding: 0px 10px;
      vertical-align: middle;
      width: auto;
      justify-content: center;
      color: #ffffff;
    }
  }
}
