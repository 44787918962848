@import "../../variables.scss";

.error {
  color: red;
  font-size: 25px;
}
p.cardStar:after {
  content: '*';
  color: red;
}
.cardStar {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  font-weight: 600;
}
.cardContainer {
  width: 51%;
  height: 75vh;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px -5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 550;
  color: rgb(107, 119, 140);
  background-color: #fff;
  position: fixed;
  top: 7%;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 22px;
  @media (max-width: 280px) {
    width: 58%;
  }
}
.cardContent {
  overflow: visible;
}
.cardTitle {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 550;
  line-height: 20px;
  color: rgb(23, 43, 77);
}
.titleContent h2 {
  margin-bottom: 2rem;
}
.titleButton {
  margin-bottom: 1rem;
  font-size: 27px;
  font-weight: 600;
  background-color: white;
  border: none;
}
.cardLabel {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  font-weight: 600;
}
.cardSelect {
  width: 47%;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: block;
  background-color: #f7f8fbf0;
  border: 0;
  font-size: 14px;
  box-shadow: none;
  line-height: 20px;
  border-radius: 4px;
}
.cardSelect:hover {
  background-color: rgb(214, 217, 221);
  transition: 0.3s;
}
.cardInput {
  width: 100%;
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #f7f8fbf0;
  box-sizing: border-box;
  display: block;
  text-align: left;
  line-height: 20px;
  border-style: solid;
  border-color: #dfe1e6;
  border-right-color: lightgray;
  border-left-color: lightgray;
  border-radius: 4px;
}
.cardInput:hover {
  background-color: rgb(214, 217, 221);
  transition: 0.3s;
}
.cardTextarea {
  width: 100%;
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #f7f8fbf0;
  box-sizing: border-box;
  display: block;
  text-align: left;
  line-height: 20px;
  border-style: solid;
  border-color: #dfe1e6;
  border-right-color: lightgray;
  border-left-color: lightgray;
  border-radius: 4px;
  min-height: 126px;
}
.cardAssignee {
  width: 100%;
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #f7f8fbf0;
  box-sizing: border-box;

  border-style: solid;
  border-color: #dfe1e6;
  display: block;
  text-align: left;
  line-height: 20px;
  border-radius: 4px;
  background-image: url(https://avatar-management.services.atlassian.com/default/48);
  background-position: 10px 7px;
  background-repeat: no-repeat;
  background-size: 27px;
  padding-left: 45px;
}
.cardButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;
  padding-top: 22px;
  padding-bottom: 24px;
}
.cancelButton {
  width: 45px;
  height: 33px;
  background-color: white;
  cursor: pointer;
  border: 2px;
  font-size: 14px;
  color: rgb(107, 119, 140);
  margin-left: 568px;
  font-weight: 500;
  border-radius: 3px;
  appearance: auto;
}
.createButton {
  width: 65px;
  height: 33px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: $primaryColor;
  border: none;
  margin-left: 20px;
  border-radius: 4px;
}

