.anyWhereContent{
   
    max-width: 638px;
    width: 100%;
    @media (max-width: 768px) {
        width: 60%;
      }   

    h2{
        color:#4F5366;
        font-size: 44px;
        margin: 0 0 20px;
        font-weight: 600;
    }
    p{
        color:#9191A1;
        margin: 0 0 40px;
        font-size: 20px;
        font-weight: 400;
    }
}

.imgPhone {
    max-width: 250px;
    width: 100%;
    @media (max-width: 768px) {
        width: 80%;
      }   
}

.anywhereSection{
    margin: 60px auto;
    @media (max-width: 768px) {
        flex-direction: column;
    }   
}

.phone{
    max-width: 312px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        justify-content: center;
      }   
}