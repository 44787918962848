.container {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1rem;
  .activity {
    font-family: inherit;
    font-size: 0.8em;
    font-style: inherit;
    margin-top: 20px;
    line-height: 10px;
    color: var(--ds-text, #172b4d);
    overflow-wrap: break-word;

    .showCommentButton {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.42857142857143;
      }

      .switchButton {
        max-width: 100%;
        cursor: pointer;
        height: 1.9em;
        background-color: white;
        border: 0;
        outline: none;
        font-weight: bold;
        margin-right: 10px;
      }

      .showContents {
        color: #1565cd;
      }

      .hideContents {
        color: gray;
      }
    }
  }
  .commentInputField {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 8px;
    margin-left: 3px;
    .avatar {
      border-radius: 50%;
      background-position: center center;
      background-size: cover;
      height: 32px;
      max-width: 32px;
    }
    input {
      background-color: white;
      border: 1px solid rgb(223, 225, 230);
      border-radius: 3px;
      box-sizing: border-box;
      height: 40px;
      padding-left: 20px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 0.875rem;
      width: 100%;
      font-weight: 400;
      line-height: 1.42857;
      color: rgb(94, 108, 132);
      margin-left: 10px;

      &:hover {
        border-color: rgb(118, 118, 118);
      }
    }
  }
  .commentButton {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 45px;
  }
  .cancelButton {
    margin-left: 4px;
    border-width: 0px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
    font-family: inherit;
    font-weight: 600;
    transition: background 0.1s ease-out 0s,
      box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: none;
    cursor: pointer;
    height: 1.8571em;
    line-height: 1.28571em;
    padding: 0px 6px;
    justify-content: center;
    color: var(--ds-text, #42526e);
  }

  .cancelButton:hover {
    background-color: #efefef;
  }

  .cancelButton span {
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    margin: 0px 2px;
  }

  .cancelButton:active {
    color: #1d0fe4 !important;
    background-color: #a4b5ed;
    filter: brightness(1.2);
  }

  .saveButton {
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: 14px;
    font-style: normal;
    font-family: inherit;
    font-weight: 700;
    transition: background 0.1s ease-out 0s,
      box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    background: var(--ds-background-brand-bold, #0052cc);
    cursor: pointer;
    height: 1.8em;
    line-height: 1.8em;
    padding: 0px 10px;
    color: #fff;
  }

  .saveButton:hover {
    filter: brightness(1.2);
  }

  .saveButton:active {
    background-color: var(--ds-background-brand-bold, #0052cc);
    filter: grayscale(0.2);
  }
}
