$size-10: 0.625rem;
$size-12: 0.75rem;
$size-13: 0.8125rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-17: 1.0625rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-26: 1.625rem;
$size-28: 1.75rem;
$size-30: 1.875rem;
$size-32: 2rem;
$size-35: 2.19rem;
$size-40: 2.5rem;
$size-45: 2.8125rem;
$size-50: 3.125rem;
$size-55: 3.4375rem;
$size-65: 4.0625rem;
$size-70: 4.375rem;
$size-80: 5rem;
$color-grey-0: #292d34;
$color-grey-1: #7c828d;
$color-grey-2: #b9bec7;
$color-grey-3: #e9ebf0;
$color-grey-4: #fafbfc;
$color-white: #fff;
$color-black: #000;
$color-pink: #fd71af;
$color-pink-dark: #de1798;
$color-red: #fd7171;
$color-orange: #ffa500;
$color-blue-light: #a8e8fa;
$color-blue: #5bc5fa;
$color-cyan: #49ccf9;
$color-green-light: #c1ffb0;
$color-green: #6bc950;
$color-green-dark: #27ae60;
$color-teal: #5cc0bf;
$color-teal-light: #8afcde;
$color-yellow-light: #fff0c3;
$color-yellow: #f7ce51;
$color-yellow-dark: #f5cd51;
$color-brand-light: #b993ff;
$color-brand: #6a2add;
$color-purple: #7b42d1;
$color-purple-dark: #8041d0;
$color-background: #fafbfc;
$color-brand-facebook: #3b5998;
$color-brand-instagram: #c32aa3;
$color-brand-linkedin: #0077b5;
$color-brand-twitter: #1da1f2;
$color-brand-youtube: red;
$color-success: var($color-green);
$color-error: var($color-red);
$bg-main: var($color-grey-4);
$bg-secondary: var($color-white);
