@import '../../../variables.scss';
$white: rgb(245, 245, 245);

.container {
  box-sizing: border-box;
  width: 100%;
  color: $white;
  max-width: 500px;
  min-height: 100vh;
  height: 100%;
  background-color: white;
  padding: 30px 15px 30px 70px;
  overflow-y: scroll;
  font-family: Poppins, sans-serif;
  z-index: 9;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.showMenu {
  left: 240px;
}

.hideMenu {
  left: -550px;
}

.container::-webkit-scrollbar {
  width: 1px;
  border-radius: 5px;
}

.container::-webkit-scrollbar-thumb {
  background: rgb(219, 220, 225);
  border-radius: 5px;
}

.dividingLine {
  width: 100%;
  height: 3px;
  background-color: $white;
  border-radius: 2px;
  margin: 20px 0px;
}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .category {
    border: none;
    text-align: left;
    background-color: transparent;
    color: $white;

    span {
      visibility: hidden;
      margin-right: 3px;
    }
    font-weight: bold;
    font-size: 12px;
    padding: 8px 0px;
    margin: 0;
    cursor: pointer;
  }
  .category:hover {
    span {
      visibility: visible;
    }
  }
  .navBtn {
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    text-decoration: inherit;
    outline: 0px;
    border: 0px;
    font-size: 15px;
    width: 100%;
    height: 50px;
    color: rgb(79, 83, 102);
    &:hover {
      //background:rgb(226, 226, 233);
      border-right: 3px solid $primaryColor;
      border-radius: 0;
      color: $primaryColor;
      font-weight: 300;
      .pencilLine {
        display: block;
      }
      svg {
        fill: $primaryColor;
      }
    }

    cursor: pointer;
    background-color: transparent;
    font-weight: 500;
    padding: 8px 14px;
    position: relative;
    align-items: center;
    svg,
    img {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }

    .pencil {
      right: 0;
      position: absolute;
      border-width: 0px;
      border-radius: 10px;
      box-sizing: border-box;
      font-weight: 500;
      text-decoration: none;
      white-space: nowrap;
      background: none;
      cursor: pointer;
      padding: 0px;

      .pencilLine {
        width: 22px;
        height: 22px;
        display: none;
        color: $white;
      }
    }
  }

  .shortcutContent {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.utils {
  margin-top: 20px;
}

.header {
  display: inline-block;
  font-style: normal;
  font-stretch: normal;
  word-break: break-word;
  font-family: Poppins, sans-serif;
  line-height: 1.43;
  color: rgb(79, 83, 102);
  font-size: 22px;
  font-weight: 600;
  padding: 0px 0px 0px 15px;
}

.recentActivityHeader {
  color: rgb(11, 14, 13);
  font-size: 14px;
  font-weight: 700px;
}

.searchItem {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 40px;
  transition: all 0.25s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #ebebeb;
  }

  .icon {
    border-radius: 50%;
    background-color: $primaryColor;
    padding: 10px;
    font-size: 17px;
    color: white;
  }

  p {
    font-size: 15px;
    margin-left: 12px;
    color: black;
    text-decoration: none;
  }
}
