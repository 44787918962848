@import '../../../variables.scss';
$lightColor: $primaryColor;

.navContainer {
  min-width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  position: sticky;
  right: 102px;
  top: 150px;
  max-height: 400px;
  padding: 40px 30px;
  width: 20%;
  @media (max-width: 900px) {
    padding: 20px 20px;
    right: 0;
    max-height: 370px;
  }
  @media (max-width: 600px) {
    min-width: 180px;
    padding: 10px 20px;
    max-height: 307px;
  }
  @media (max-width: 500px) {
    min-width: 134px;
    max-height: 270px;
    right: 9px;
  }
  @media (max-width: 425px) {
    min-width: 120px;
    max-height: 250px;
    right: 14px;
  }
  @media (max-width: 375px) {
    min-width: 110px;
    max-height: 280px;
    right: 9px;
  }
  @media (max-width: 360px) {
    min-width: 98px;
    padding: 10px 18px;
    max-height: 224px;
    right: 20px;
  }
  h4 {
    @media (max-width: 425px) {
      margin: 10px 0;
    }
    li {
      list-style: none;
      a {
        text-decoration: none;
        font-size: 22px;
        font-weight: 700;
        line-height: 37.5px;
        color: rgb(40, 40, 40);
        margin: 10px 0 0 0;
        @media (max-width: 900px) {
          font-size: 19px;
          font-weight: 600;
        }
        @media (max-width: 600px) {
          font-size: 17px;
          line-height: 24.5px;
        }
        @media (max-width: 500px) {
          font-size: 16px;
          line-height: 20.5px;
        }
        @media (max-width: 360px) {
          font-size: 14px;
        }
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      border-left: 5px solid #f6f7f9;
      @media (max-width: 425px) {
        border-left: 3px solid #f6f7f9;
      }
      a {
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 55px;
        color: #9294a2;
        padding-left: 40px;
        transition: all 0.25s ease-in-out;
        @media (max-width: 600px) {
          line-height: 45px;
          padding-left: 25px;
        }
        @media (max-width: 500px) {
          line-height: 35px;
          padding-left: 9px;
        }
        @media (max-width: 425px) {
          font-size: 13px;
        }
        @media (max-width: 375px) {
          font-size: 12px;
        }
        @media (max-width: 360px) {
          font-size: 11px;
          line-height: 25px;
          padding-left: 5px;
        }
        &:hover {
          font-weight: 600;
          color: rgb(47, 47, 47);
        }
      }
    }

    .active {
      position: relative;
      a {
        font-weight: 900;
        color: $primaryColor;
        &:hover {
          font-weight: 900;
          color: $primaryColor;
        }
      }

      &::before {
        position: absolute;
        content: ' ';
        background-color: $primaryColor;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        top: 21px;
        left: -9px;
        @media (max-width: 600px) {
          top: 15px;
        }
        @media (max-width: 500px) {
          top: 10px;
        }
        @media (max-width: 425px) {
          width: 11px;
          height: 11px;
          top: 12px;
          left: -8px;
        }
        @media (max-width: 375px) {
          width: 11px;
          height: 11px;
          top: 10px;
          left: -8px;
        }
        @media (max-width: 360px) {
          top: 6px;
        }
      }

      &::after {
        position: absolute;
        width: 5px;
        height: 21px;
        content: '';
        top: 17px;
        left: -5px;
        background: $primaryColor;
        @media (max-width: 600px) {
          top: 11px;
        }
        @media (max-width: 500px) {
          top: 6px;
        }
        @media (max-width: 375px) {
          top: 5px;
        }
        @media (max-width: 360px) {
          top: 1px;
        }
      }
    }
  }
}
