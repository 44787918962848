.label {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  height: 35px;
  align-items: center;
  font-size: 16px;

  .leftContent {
    display: flex;
    align-items: center;
    width: 50%;
    .reactIcon {
      margin-right: 5px;
    }
  }

  & > div:nth-of-type(1) {
    line-height: 1;
    color: var(--ds-text-subtle, #38465e);
    font-weight: 500;
  }

  & > div:nth-of-type(2) {
    font-size: 14px;
    margin-top: 0;
    line-height: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 100%;
    border: 2px solid transparent;
    border-radius: 3px;
    align-items: center;
  }
  .labelDropdownContainer {
    position: relative;
    box-sizing: border-box;
    margin-top: 6px;
    .labelDropdownOpen {
      display: flex;
      min-height: 44px;
      width: 100%;
      max-width: 200px;
      flex-direction: column;
      position: relative;
      top: 73px;
      .labelOptions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #ffffff;
        border-color: #4c9aff;
        align-items: stretch;
        border-radius: 3px;
        border-style: solid;
        border-width: 2px;
        box-shadow: none;
        gap: 5px;
        padding: 6px 6px 6px 6px;
        cursor: default;
        .labels {
          display: inline-flex;
          align-items: center;
          background-color: rgb(244, 245, 247);
          height: 20px;
          span {
            display: inline-flex;
            margin-left: 7px;
            min-width: 0px;
            max-width: 100%;
            flex: 1 1 100%;
            line-height: 1;
            border: none;
            cursor: pointer;
            outline: none;
            font-weight: 400;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgb(37, 56, 88);
          }
          svg {
            cursor: pointer;
            color: black;
            &:hover {
              background-color: #f9977c;
            }
          }
        }
        input {
          border: none;
          &:focus-visible {
            outline-color: #fff;
          }
        }
      }
      .labelMenu {
        cursor: pointer;
        width: 100%;
        overflow-y: scroll;
        z-index: 999;
        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          background-color: rgb(255, 255, 255);
          border: rgb(202, 200, 200);
          border-style: solid;
          border-radius: 3px;
          border-width: 1px;
          min-height: 40px;
          padding: 4px 0;
          max-height: 139px;
          overflow-y: scroll;
          box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px,
            rgb(9 30 66 / 31%) 0px 0px 20px -6px;
          li {
            width: 100%;
            button {
              display: flex;
              align-items: center;
              height: 49px;
              padding-left: 11px;
              border: none;
              background-color: rgb(255, 255, 255);
              width: 100%;
              cursor: pointer;
              &:hover {
                border-style: solid;
                border-left-color: rgb(0, 82, 204);
                border-right: none;
                border-top: none;
                border-bottom: none;
                background-color: rgb(244, 245, 247);
              }
              span {
                display: flex;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                text-transform: none;
                flex: 1 1 100%;
                color: rgb(23, 43, 77);
                white-space: nowrap;
              }
            }
          }
          .noResult {
            display: flex;
            justify-content: center;
            color: rgb(108, 108, 108);
            margin: 12px 0 0 0;
          }
        }
      }
    }
    .labelInputClose {
      cursor: pointer;
      min-height: 28px;
      padding: 4px 0;
      width: 100%;
      outline: 0px;
      align-items: center;
      display: flex;
      flex: 1 1;
      overflow: hidden auto;
      position: relative;
      margin: 0;
      border: none;
      background-color: #fff;
      flex-wrap: wrap;
      gap: 5px;
      span {
        display: inline-flex;
        font-size: 16px;
        justify-content: center;
        color: rgb(0, 82, 204);
        background-color: #f4f5f7;
        font-weight: 400;
        padding: 2px 4px;
        &:hover {
          color: #0065ff;
          background-color: #ebecf0;
          text-decoration: underline;
        }
      }
      .noLabel {
        color: #5e6c84;
        background-color: #fff;
        font-size: 16px;
        &:hover {
          color: #5e6c84;
          background-color: #f4f5f7;
          text-decoration: none;
        }
      }
    }
  }
}
